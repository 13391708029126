import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import firebase, { firestore } from 'firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import SideBar from './Sidebar';
import NavBar from './Navbar';
import Plantilla from '../widgets/Plantilla';
import { UISectionDefinition } from 'simplypro-db';
import Calculos from './Calculos';
import checkwhite from '../../assets/Logoblanco.png';
import titleLogo from '../../assets/Logoblanco.png';
import { getValue } from '../../helpers';
import { setGraphicsState } from 'pdf-lib';
import InfoToolTip from '../widgets/InfoTooltip';

interface RouterParams {
  declaracionId: string;
  seccionKey: string;
}

type DocumentosCondicionales = {
  esEmpleado: boolean;
  esTrabajadorIndependiente: boolean;
  haDeclaradoRenta: boolean;
  dobleTributacion: boolean;
  miembroFFMMyPolicia: boolean;
  indemnizacionesYSeguros: boolean;
  diplomaticos: boolean;
  accionesYSociedades: boolean;
  tieneProductosFinanciero: boolean;
  casaOApartamento: boolean;
  vehiculo: boolean;
  otrosBienesMuebles: boolean;
  ingresosDeRegaliasYPI: boolean;
  bienesInmuebles: boolean;
  donaciones: boolean;
  interesesRendimientosFinancieros: boolean;
  esPensionado: boolean;
  negocioAgropecuario: boolean;
  todosMenosEmpleado: boolean;
};

const Declaracion: React.FC = (props: any) => {
  const { declaracionId, seccionKey = 'perfil' } = useParams<RouterParams>();
  const [user] = useAuthState(firebase.auth());
  const [declaracion, loading] = useDocumentData<any>(
    firebase.firestore().collection(`/usuarios/${user?.uid}/declaraciones`).doc(declaracionId)
  );

  // const [definicionRef, setDefinicionRef] = useState()
  const [seccionesUI, setSeccionesUI] = useState<UISectionDefinition[]>();

  useEffect(() => {
    const getSeccionesUIAsync = async (declaracion: any) => {
      const definicionRef = declaracion.definicionRef as firestore.DocumentReference;
      const seccionesUIQueryResult = await definicionRef.collection('seccionesUI').orderBy('indice', 'asc').get();
      setSeccionesUI(seccionesUIQueryResult.docs.map((d) => d.data() as UISectionDefinition));
    };
    if (declaracion) {
      getSeccionesUIAsync(declaracion);
    }
  }, [declaracion]);

  const [isOpen, setOpen] = useState<boolean>(true);
  const [labelsVisible, setLabelsVisible] = useState<boolean>(true);
  const [expanded, setexpanded] = useState<boolean>(true);
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [showDocumentsModal, setShowDocumentModal] = useState(false);
  const [documentosCondicionales, setDocumentosCondicionales] = useState<DocumentosCondicionales>();

  const setSectionLoaded = (loadedState: boolean) => {
    setLoaded(loadedState);
  };

  const toggle = () => {
    const newState = !isOpen;
    const timeout = newState ? 500 : 0;
    const timeout2 = newState ? 0 : 500;
    setOpen(newState);
    setTimeout(() => {
      setLabelsVisible(newState);
    }, timeout);
    setTimeout(() => {
      setexpanded(newState);
    }, timeout2);
  };

  const getSectionRoutes = (declaracionId: string, seccionesUI: UISectionDefinition[]) => {
    const result = seccionesUI.map((s) => {
      return (
        <Route
          key={s.id}
          exact
          path={`/declaraciones/${declaracionId}/${s.id}`}
          render={() => (
            <Plantilla
              setLoaded={setSectionLoaded}
              declaracionId={declaracionId}
              seccionUI={s.id}
              definicionId={declaracion.definicionRef.id}
            />
          )}
        />
      );
    });

    result.push(
      <Route key={declaracionId} exact path={`/declaraciones/${declaracionId}/`}>
        <Redirect to={`/declaraciones/${declaracionId}/${seccionesUI[0].id}`} />
      </Route>
    );
    result.push(
      <Route
        key={'calculos'}
        exact
        path={`/declaraciones/${declaracionId}/calculos`}
        render={() => <Calculos declaracionId={declaracionId} />}
      />
    );
    return result;
  };

  interface RespuestaMultiplesValores {
    seleccion: string[];
  }

  const continuar = () => {
    if (seccionKey === 'perfil') {
      //ingresos
      const P07 = getValue<RespuestaMultiplesValores>(declaracion, 'P07')?.seleccion;
      // bienes
      const P08 = getValue<RespuestaMultiplesValores>(declaracion, 'P08')?.seleccion;
      //productos financieros
      const P09 = getValue<RespuestaMultiplesValores>(declaracion, 'P09')?.seleccion;
      //descuentos
      const P13 = getValue<RespuestaMultiplesValores>(declaracion, 'P13')?.seleccion;
      const P15 = getValue<RespuestaMultiplesValores>(declaracion, 'P15')?.seleccion;
      const P16 = getValue<boolean>(declaracion, 'P16');
      const P23 = getValue<boolean>(declaracion, 'P23');
      const P19 = getValue<boolean>(declaracion, 'P19');
      const esEmpleado = P07?.includes('P07-1');
      const esTrabajadorIndependiente = P07?.includes('P07-2') && !P16;
      const haDeclaradoRenta = !P23;

      const casaOApartamento = P08.includes('P08-1');
      const bienesInmuebles = P08.includes('P08-2');
      const vehiculo = P08.includes('P08-3');
      const otrosBienesMuebles = P08.includes('P08-5');

      const accionesYSociedades = P07?.includes('P07-5') || P07?.includes('P07-6') || P09?.includes('P09-91');
      const ingresosDeRegaliasYPI = P07.includes('P07-51');
      const interesesRendimientosFinancieros = P07.includes('P07-5');

      const diplomaticos = P15?.includes('P15-9');
      const miembroFFMMyPolicia = P15?.includes('P15-1');

      const tieneProductosFinanciero = P09.length > 0 && !P09.includes('P09-94');
      const donaciones = P13.includes('P13-1');
      const dobleTributacion = P19;
      const indemnizacionesYSeguros = true;

      const esPensionado = P07.includes('P07-4');
      const negocioAgropecuario = false;

      const todosMenosEmpleado = P07.length > 1 || (P07.length ===1 && !esEmpleado);

      const condicionales: DocumentosCondicionales = {
        esEmpleado,
        esTrabajadorIndependiente,
        haDeclaradoRenta,
        casaOApartamento,
        bienesInmuebles,
        vehiculo,
        accionesYSociedades,
        diplomaticos,
        miembroFFMMyPolicia,
        tieneProductosFinanciero,
        donaciones,
        otrosBienesMuebles,
        dobleTributacion,
        ingresosDeRegaliasYPI,
        indemnizacionesYSeguros,
        interesesRendimientosFinancieros,
        esPensionado,
        negocioAgropecuario,
        todosMenosEmpleado
      };
      setDocumentosCondicionales(condicionales);
      setShowDocumentModal(true);
      return;
    }
    if (seccionesUI) {
      const currentSectionIndex = seccionesUI?.findIndex((s) => s.id === seccionKey);
      if (currentSectionIndex !== undefined) {
        const nextSectionIndex = currentSectionIndex + 1;
        history.push(`/declaraciones/${declaracionId}/${seccionesUI[nextSectionIndex].id}`);
      }
    }
  };

  const docCheckListo = () => {
    firebase.firestore().doc(`/usuarios/${user?.uid}/declaraciones/${declaracionId}/`).update({ docCheck: true });
    if (seccionesUI) {
      const currentSectionIndex = seccionesUI?.findIndex((s) => s.id === seccionKey);
      if (currentSectionIndex !== undefined) {
        const nextSectionIndex = currentSectionIndex + 1;
        history.push(`/declaraciones/${declaracionId}/${seccionesUI[nextSectionIndex].id}`);
      }
    }
    setShowDocumentModal(false);
  };

  const continuarActivado = declaracion && declaracion[seccionKey] === 'completado';

  return (
    <>
      <Row>
        {declaracion && (
          <Col>
            <NavBar toggle={toggle} title={declaracion.titulo} />
          </Col>
        )}
      </Row>
      <Row>
        {seccionesUI && declaracion && (
          <Col md={expanded ? 3 : 1}>
            <SideBar
              declaracionId={declaracionId}
              toggle={toggle}
              isOpen={isOpen}
              labelsVisible={labelsVisible}
              declaracion={declaracion}
              seccionKey={seccionKey}
              seccionesUI={seccionesUI}
            />
          </Col>
        )}
        {!loading && declaracion && seccionesUI && (
          <Col md={expanded ? 9 : 11}>
            <div style={{ width: '100%', height: '100%' }}>
              <Switch>{getSectionRoutes(declaracionId, seccionesUI)}</Switch>
              <Button
                style={{
                  visibility: loaded && seccionKey !== 'adicional' && seccionKey !== 'calculos' ? 'visible' : 'hidden'
                }}
                onClick={continuar}
                disabled={!continuarActivado}
              >
                Continuar
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <Modal size="lg" show={showDocumentsModal} onHide={() => setShowDocumentModal(false)}>
        <Modal.Header>
          <Modal.Title>
            {' '}
            <h2>
              Gracias por completar tu Perfil <img src={titleLogo} id="title-logo" alt="logo" />
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <p id='welcome-message'>¡Gracias por confiar en nosotros! </p>
                                    <p id='welcome-message2'>Este 20 de Julio te contaremos como puedes independizarte y hacer tu declaración de renta tu mismo, sin intermediarios, de manera fácil, confiable, ¡y totalmente gratis!</p> */}
            <p id="welcome-message4">
              Ya hemos personalizado tu experiencia para que tengas que responder solo las preguntas que te corresponden
              y nada adicional.
            </p>

            <p id="welcome-message4">
              Antes de continuar con tu declaración de renta te recomendamos que tengas a la mano los siguientes
              documentos para que puedas ingresar tu información de manera adecuada, rápida y sencilla.
            </p>

            <h5>Documentos Requeridos</h5>

            <ul className="">
              <li className="list-item-welcome">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Copia actualizada del certificado de inscripción en el RUT Si aun no tienes el RUT,{' '}
                <a className="link" href="/blog/RUT" target="_blank">
                  {' '}
                  en este link
                </a>{' '}
                te enseñamos como conseguirlo
              </li>
              <li className="list-item-welcome">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Usuario y contraseña de la Plataforma Muisca de la DIAN. <a className="link"  href="/blog/usuarioDIAN" target="_blank" >Acá te explicamos como obternerlos</a>
              </li>
              <li className="list-item-welcome">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Firma electrónica. <a className="link"  href="/blog/firmaDIAN" target="_blank" >Acá te explicamos como obtenerla</a>
              </li>
              {documentosCondicionales && documentosCondicionales.haDeclaradoRenta && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Formulario 210 del año anterior.
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.esEmpleado && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de Ingresos y Retenciones suministrado por tu empleador.
                  </li>

                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de pagos por concepto de alimentación, efectuados por su empleador.
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.esTrabajadorIndependiente && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de Ingresos por concepto de honorarios, comisiones y servicios.
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.todosMenosEmpleado && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de pagos a cajas de compensación, empresas promotoras de salud (EPS), fondos de
                    pensiones, administradoras de riesgos laborales (ARL).
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.miembroFFMMyPolicia && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de salario básico FFMM o Policia.
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.diplomaticos && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de prima especial y prima de costo de vida
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.tieneProductosFinanciero && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados y/o extractos bancarios de todos los productos financieros que seleccionaste en el
                    Perfil. La fecha de corte de estos certificados y/o extractos debe ser 31 de Diciembre del año a
                    declarar.
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.esTrabajadorIndependiente && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Facturas de gastos, indicando valor total. Esto aplica únicamente si tuviste gastos facturados por
                    concepto de tu trabajo como independiente o tu negocio.
                  </li>

                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Relación de pagos efectuados a sus empleados por concepto de sueldos, bonificaciones, vacaciones,
                    cesantías y otros. Esto aplica únicamente si tienes empleados en tu negocio o tus funciones como
                    independiente.
                  </li>

                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Pagos por concepto de impuestos de Industria y Comercio, Avisos y Tableros y Predial.
                  </li>
                </>
              )}

{documentosCondicionales && documentosCondicionales.dobleTributacion && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados que respalden exenciones para extranjeros contempladas en tratados o convenios
                    internacionales.
                  </li>
                </>
              )}


{documentosCondicionales && documentosCondicionales.interesesRendimientosFinancieros && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de los rendimientos financieros (GMF) pagados durante el año, expedidos por las
                    entidades correspondientes.
                  </li>
                </>
              )}

{documentosCondicionales && documentosCondicionales.accionesYSociedades && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de dividendos y participaciones recibidos durante el año, expedidos por las sociedades
                    de las cuales es socio o accionista.
                  </li>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de ingresos recibidos durante el año por concepto de utilidades repartidas por
                    sociedades liquidadas.
                  </li>
                </>
              )}

{documentosCondicionales && documentosCondicionales.esPensionado && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de indemnizaciones sustitutivas de la pensión o devoluciones de saldos de ahorro
                    pensional.
                  </li>
                </>
              )}

{documentosCondicionales && documentosCondicionales.casaOApartamento && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de pago del impuesto predial del año a declarar.
                  </li>
                </>
              )}

{documentosCondicionales && documentosCondicionales.vehiculo && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Factura(s) de compra o cualquier documento que soporte el valor de compra del vehículo(s).
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.otrosBienesMuebles && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Lista de los muebles, enseres, maquinaria y equipo, incluyendo su valor de compra más adiciones y
                    mejoras.
                  </li>
                </>
              )}

              {documentosCondicionales && documentosCondicionales.ingresosDeRegaliasYPI && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de avalúo técnico de otros bienes tales como derechos de autor, propiedad industrial,
                    literaria, artística, científica y otros.
                  </li>
                </>
              )}

              {documentosCondicionales &&
                (documentosCondicionales.bienesInmuebles || documentosCondicionales.casaOApartamento) && (
                  <>
                    <li className="list-item-welcome">
                      <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                      Certificado de pagos de intereses por préstamos para adquisición de vivienda.
                    </li>
                  </>
                )}

              {documentosCondicionales && documentosCondicionales.donaciones && (
                <>
                  <li className="list-item-welcome">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificados de donaciones.
                  </li>
                </>
              )}

{documentosCondicionales && documentosCondicionales.indemnizacionesYSeguros && (
                <>
                  <li className="list-item-welcome d-flex">
                    <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                    Certificado de indemnizaciones.
                    <InfoToolTip
                      containerClass={'marginAuto'}
                      id="indemizaciones-cert"
                      ayuda="Posibles
indemnizaciones que tengas por accidentes de trabajo o de enfermedad, maternidad,
gastos de entierro del trabajador, pagos de seguros de vida, seguro por muerte y
compensaciones por muerte. Si no te aplica o no conoces sobre este tema, no te
preocupes…quiere decir que no necesitas este documento"
                    />
                  </li>
                </>
              )}

              {/* <li className="list-item-welcome">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Letras, pagares, hipotecas y otros documentos que respalden cuentas por cobrar o deudas (cuentas por
                pagar).
              </li> */}

<li className="list-item-welcome">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Certificados por pagos de salud.
              </li>


              <li className="list-item-welcome">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Otros documentos que respalden ingresos, costos, deducciones, activos (bienes) y/o deudas.
              </li>



            </ul>

            <p className="docsParagraph">
              Esta lista es preliminar e informativa para guiarte de una mejor forma en tu proceso de declaración. Si
              tienes otros documentos que consideres importantes y que no están en esta lista, úsalos también y tenlos a
              la mano para que puedas tener lista tu declaración de renta en minutos.
            </p>
            <p className=" docsParagraphFinal">
              Recuerda guardar todos estos documentos y archivos en una carpeta en tu computador personal para que los
              tengas como soporte, en caso de ser requeridos por la DIAN.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={docCheckListo} variant="primary">
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Declaracion;
