import React, { useEffect, useState } from 'react';
import { Navigation } from '../components/Navigation';
import { Fecha } from '../components/Fecha';
import checkblue from '../assets/Logoazulos.png';
import checkwhite from '../assets/Logoblanco.png';
import '../styles/ContenidoImpuestos.css';
import { Button } from 'react-bootstrap';
import firebase from 'firebase';
import { Loader } from '../components/Loader';
import Stepper from '../components/widgets/Stepper/Stepper';
import { ResidenciaFiscalSteps } from '../components/preparacion/Preparación';
import { useParams } from 'react-router-dom';
import { useQuery } from '../utils/routes';
import { useAuthState } from 'react-firebase-hooks/auth';
//import { auth } from '../firebase';


type FormData = {
  nombre: string;
  correo: string;
};

type ArgsDigitosCedula = {
  formSubmissionId: string;
  ultimosDosDigitosCeluda:string;
}

const ContenidoImpuestos: React.FC = () => {
  const query  = useQuery();
  const [user] = useAuthState(firebase.auth())

  const [enviado, setEnviado] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [formSumissionId, setFormSubmissionId]= useState<string>();
  const enviarFormulario = firebase.functions().httpsCallable('SendFormularioComenzar');
  const enviarDigitosCedula = firebase.functions().httpsCallable('SendUltimosDigitosCedula');
  const [formData, setFormData] = useState<Partial<FormData>>({});
  const [checkResidencia, setCheckResidencia] = useState(false);

  useEffect(()=>{
    const correo = query.get('email') || user?.email || undefined;
    const nombre = query.get('name') || user?.displayName || undefined;
    setFormData({correo, nombre});
    if(correo && nombre){
      doSumbit().then(()=> console.log('submitted'))
    }

  },[user, query])



  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await doSumbit()

  };

  const doSumbit = async ()=>{
    setEnviando(true);
    try{
      const result = await enviarFormulario(formData);
      setFormSubmissionId(result.data)
      setEnviando(false);
      setEnviado(true);
    } catch(e){
      console.error('unable to send form data')
    }
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.validity.valid) {
      setFormData({ ...formData, correo: event.target.value });
    }
  };
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.validity.valid) {
      setFormData({ ...formData, nombre: event.target.value });
    }
  };

  const onDigitosCedulaInput = (value:string)=>{
    if(formSumissionId){
        const args : ArgsDigitosCedula = {formSubmissionId:formSumissionId,ultimosDosDigitosCeluda: value }
        enviarDigitosCedula(args).then(r=> console.log('succesfullySaved')).catch(e=> console.error(e));
    }
  }
  const configPath = "/preguntas/declaracion-2019/preparacion/s01";
  const resultCalculation = (doc:ResidenciaFiscalSteps)=>{
      return !!doc.step1 || (!!!doc.step2 && !!doc.step3);
  }
  const done = async  (value:boolean) =>{ 
    console.log('done')
  }

  return (
    <>
      <Navigation />
      {!enviado && (
        <div id="f-container">
          <div id="c-form">
            <form action="" method="post" onSubmit={handleFormSubmit}>
              <h1 id="form-title">
                Verifica si debes declarar renta y cuándo <img src={checkblue} alt="logocheck" className="check-logo" />
              </h1>
              <label htmlFor="name">Nombre</label>
              <input type="text" id="name" name="name" value={formData.nombre} onChange={onNameChange} required />
              <label htmlFor="email">Correo</label>
              <input type="email" id="email" name="email" value={formData.correo} onChange={onEmailChange} required />
              <button type="submit" id="submit">
                Comencemos
              </button>
            </form>
          </div>
        </div>
      )}
      {enviando ? (
        <Loader />
      ) : enviado ? (
        <>
          <div id="list-container">
            <p className="start">
              Están obligadas a declarar renta las personas naturales que cumplan alguna de las siguientes condiciones:
            </p>
            <ul className="list">
              <li className="list-item">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Poseer un patrimonio bruto que sea igual o superior a $171.018.000 a 31 de diciembre de 2022
              </li>
              <li className="list-item">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Obtener ingresos totales iguales o superiores a $53.206.000 durante el año
              </li>
              <li className="list-item">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Tener consumos en tarjetas de crédito iguales o superiores a $53.206.000
              </li>
              <li className="list-item">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Haber acumulado en compras y consumos valores iguales o superiores a $53.206.000 durante el 2022
              </li>
              <li className="list-item">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Tener un valor total acumulado de consignaciones bancarias, depósitos o inversiones financieras por
                valor igual o superior a $53.206.000 durante el año
              </li>
              <li className="list-item">
                <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                Ser responsable de IVA a cierre del año 2022
              </li>
            </ul>
          </div>
          <div id="date-container">
            <p className="dec-date">Revisa tú fecha para límite para declarar</p>
            <Fecha onDigitosCedulaInput={onDigitosCedulaInput} />
          </div>
          <div className='wizzard-residencia'>
            <p className='residencia-text'>Conoce si eres residente fiscal en Colombia con nuestro test</p>
            {!checkResidencia && 
            <Button id='residence-button' onClick={()=> setCheckResidencia(true)}>Iniciar</Button>}
            {checkResidencia && <Stepper <ResidenciaFiscalSteps> configPath={configPath} done={done} resultCalculation={resultCalculation}> </Stepper> }
          </div>
          <div id="begin-container">
            <h1 className="texto-content">
              Ya tienes la información que necesitas para conocer si debes declarar y cuando
            </h1>
            <p className="p-content">
              Ahora puedes iniciar el proceso en nuestra plataforma y obtener tú declaración de renta de manera rápida,
              económica y confiable
            </p>
            <Button id="begin-button" className="btn-margin" href="/ingresar">
              {user? 'Haz tu declaración':'Regístrate'}
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};

export { ContenidoImpuestos };
