import React from "react";
import { Calendly } from "./Calendly";
import '../styles/Cita.css';

const Cita: React.FC = () => {
    return (
        <section className="section-6">
            <h1 className="h-section">Si deseas conocer más de nuestra plataforma y cómo funciona agendemos una cita</h1>
            <p className="p-section">Sigue las instrucciones del siguiente link y nosotros te llamaremos</p>
            <Calendly />
        </section>
    )
}

export { Cita }