// Stolen from https://github.com/mu29/react-stepper
import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import StepSymbol from './StepSymbol';

const styles = {
  root: {
    width: '100%',
    minHeight: 0,
    padding: 0,
  },
  stepper: {
    display: 'table',
    width: '100%',
    margin: '0 auto',
  },
};

const StepNavigation: React.FC<Partial<StepperProperties>> = ({ activeStep=0, steps, disabledSteps,
  activeColor, completeColor, defaultColor, circleFontColor,
  activeTitleColor, completeTitleColor, defaultTitleColor,
  size, circleFontSize, titleFontSize,
  circleTop, titleTop, completeOpacity, activeOpacity, defaultOpacity,
  completeTitleOpacity, activeTitleOpacity, defaultTitleOpacity, barStyle,
  defaultBorderColor, completeBorderColor, activeBorderColor, defaultBorderStyle,
  completeBorderStyle, activeBorderStyle, defaultBarColor, completeBarColor, lineMarginOffset, defaultBorderWidth}) =>{

  return (
    <div style={ styles.root }>
      <div style={ styles.stepper }>
        { steps?.map((step, index) => (
          <StepSymbol
            key={index}
            width={100 / steps.length}
            title={step.title}
            icon={step.icon}
            href={step.href}
            onClick={step.onClick}
            active={!(disabledSteps || []).includes(index) && index === activeStep}
            completed={!(disabledSteps || []).includes(index) && index < activeStep}
            first={index === 0}
            isLast={index === steps.length - 1}
            index={index}
            activeColor={activeColor}
            completeColor={completeColor}
            defaultColor={defaultColor}
            circleFontColor={circleFontColor}
            activeTitleColor={activeTitleColor}
            completeTitleColor={completeTitleColor}
            defaultTitleColor={defaultTitleColor}
            size={size}
            circleFontSize={circleFontSize}
            titleFontSize={titleFontSize}
            circleTop={circleTop}
            titleTop={titleTop}
            defaultOpacity={defaultOpacity}
            completeOpacity={completeOpacity}
            activeOpacity={activeOpacity}
            defaultTitleOpacity={defaultTitleOpacity}
            completeTitleOpacity={completeTitleOpacity}
            activeTitleOpacity={activeTitleOpacity}
            barStyle={barStyle}
            defaultBorderColor={defaultBorderColor}
            completeBorderColor={completeBorderColor}
            activeBorderColor={activeBorderColor}
            defaultBorderStyle={defaultBorderStyle}
            defaultBorderWidth={defaultBorderWidth}
            completeBorderStyle={completeBorderStyle}
            activeBorderStyle={activeBorderStyle}
            defaultBarColor={defaultBarColor}
            completeBarColor={completeBarColor}
            lineMarginOffset={lineMarginOffset}
          />
        )) }
      </div>
    </div>
  );
}

StepNavigation.defaultProps = {
  activeStep: 0,
};

export interface IStep {
  title?:string
  icon?:IconDefinition
  href?:string
  onClick?:(e:any)=>void
}

interface StepperProperties  {
  activeStep?: number
  disabledSteps?: Array<number>
  steps?: Array<IStep>
  activeColor?: string
  completeColor?: string
  defaultColor?: string
  activeTitleColor?: string
  completeTitleColor?: string
  defaultTitleColor?: string
  circleFontColor?: string
  size?: number
  circleFontSize?: number
  titleFontSize?: number
  circleTop?: number
  titleTop?: number
  defaultOpacity?: string
  completeOpacity?: string
  activeOpacity?: string,
  defaultTitleOpacity?: string
  completeTitleOpacity?: string
  activeTitleOpacity?: string
  barStyle?: string
  defaultBarColor?: string
  completeBarColor?: string
  defaultBorderColor?: string
  completeBorderColor?: string
  activeBorderColor?: string
  defaultBorderStyle?: string
  completeBorderStyle?: string
  activeBorderStyle?: string
  lineMarginOffset?: number
  defaultBorderWidth?: number
};

export default StepNavigation;
