import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Jumbotron } from 'react-bootstrap';
import { Navigation } from '../components/Navigation';
import '../styles/Ingresar.css';
import { auth } from '../firebase';
import firebase from 'firebase';
import { StyledFirebaseAuth } from 'react-firebaseui';
import FirebaseUIAuth from "react-firebaseui-localized";



// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/declaraciones',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
      const user = authResult.user;
      if (authResult.additionalUserInfo.isNewUser) {
        user.sendEmailVerification();
      }
      return true;
    }
  }
};

const Ingresar: React.FC = (props: any) => {

  const history = useHistory();

  useEffect(() => {
    const currentUser = auth.currentUser;
    if(!!currentUser){
      history.push("/declaraciones");
    }
    const unregisterAuthObserver = auth.onAuthStateChanged(
      (user) => {
        if(!!user){
          history.push("/declaraciones");
        }
      }
    )
    return () => {
      unregisterAuthObserver();
    }
  })

return (
  <>
    <Navigation/>
    <div className='jumbo-container'>
    <div id='contenedor'>
    <h1 id='c-h'>Independízate y haz tu declaración de renta tu mismo. Si quieres conocer más regístrate acá.</h1>
        <div id="pc"><FirebaseUIAuth  version="4.7.3" lang="es" rtl={false} config={uiConfig} auth={auth} firebase={firebase} /></div>
      </div>
    </div>
  </>
);
}

export default Ingresar;
