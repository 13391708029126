import React, { useEffect, useState } from 'react';
import firebase, { firestore } from 'firebase';
import { Col, Form, Row } from 'react-bootstrap';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import {
  esPreguntaOpcion,
  esPreguntaSiNo,
  esPreguntaTextoLibre,
  Pregunta,
  PreguntaOpcion,
  PreguntaSimple,
  PreguntaSiNo,
  Respuesta,
  RespuestaOpcionMultiple,
  RespuestaOpcionUnica,
  RespuestaSiNo,
  SeccionUI,
  PreguntaTextoLibre,
  RespuestaTextoLibre,
  esPreguntaMultipleNumerica,
  RespuestanNumeroMultiple,
  esPreguntaNumero,
  PreguntaNumero,
  RespuestaSiNoNumero,
  PreguntaMultipleValorNumerico,
  esTablaEstatica,
  TablaEstatica,
  ValorOpcionMultiple,
} from 'simplypro-db';
import { Loader } from '../Loader';
import InfoToolTip from './InfoTooltip';
import NumericoMultiple, { NumericoMultipleValue } from './NumericoMultiple';
import OpcionMultiple, { OnSelectionChanged } from './OpcionMultiple';
import OpcionUnica from './OpcionUnica';
import SiNo from './SiNo';
import SiNoNumero, { SiNoNumeroValue } from './SiNoNumero';
import Tabla from './Tabla';
import TextoLibre from './TextoLibre';
import { Declaracion, Estado, EstadoSeccion } from '../../dbTypes/Declaracione';

type DocumenDataQuerySnap = firestore.QuerySnapshot<firestore.DocumentData>;
type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement;
type PreguntaConVisibilidad = { mostrar: boolean; pregunta: Pregunta & firebase.firestore.DocumentData };

type some = string | number | boolean | object;

function hasValue(x: any | null): x is some {
  if (typeof x === 'undefined') {
    return false;
  } else if (x === null) {
    return false;
  } else {
    return true;
  }
}




const Plantilla: React.FC<{ declaracionId: string; seccionUI: SeccionUI; definicionId: string, setLoaded: (loadedState:boolean)=> void }> = ({
  declaracionId,
  seccionUI,
  definicionId,
  setLoaded
}) => {
  const [user, loadingAuth, errorAuth] = useAuthState(firebase.auth());
  const [preguntas, cargandoPreguntas, errorCargandoPreguntas] = useCollection(
    firebase
      .firestore()
      .collection(`/definiciones/${definicionId}/preguntas`)
      .where('seccionUI', '==', `${seccionUI}`)
      .orderBy('indice')
  );
  const [declaracionSnap, cargandoRespuestas, errorCargandoRespuestas] = useDocument(
    firebase.firestore().doc(`/usuarios/${user?.uid}/declaraciones/${declaracionId}`)
  );
  const [preguntasConVisibilidad, setPreguntasConVisibilidad] = useState<PreguntaConVisibilidad[]>();

  const updateRespuesta = async (respuesta: Respuesta) => {
    const key = `respuestas.${respuesta.id}`;
    const update = { [key]: respuesta } as any;


    declaracionSnap?.ref.update(update);
  };

  useEffect(() => {
    if (preguntas && declaracionSnap) {
      Promise.all(
        preguntas.docs.map(async (doc) => {
          //useCollection(firebase.firestore().collection()
          const pregunta = doc.data();
          const consdicionales = (await doc.ref.collection('condicionales').get()).docs.map(c=>c.data());
          const opciones = (await doc.ref.collection('opciones').get()).docs;
          
          if (consdicionales) {
            const condicionesObligatorias = consdicionales.filter((c) => c.obligatorio);
            const condicionesNoObligatorias = consdicionales.filter((c) => !c.obligatorio);

            const seCumple = (condicion: firestore.DocumentData) => {
              let preguntaIdProp: any = condicion.preguntaId;
              let preguntaId;
              if (typeof preguntaIdProp === 'string') {
                const n = preguntaIdProp.lastIndexOf('/');
                preguntaId = preguntaIdProp.substring(n + 1);
              } else {
                const t = typeof preguntaIdProp;
                preguntaId = preguntaIdProp.id;
              }

              console.log(condicion.valor_string)

              const respuesta = getRespuestaById(preguntaId, declaracionSnap);
              let condicionCumplida = false;

              const valor = respuesta?.valor;

              switch (condicion.operacion) {
                case '>':
                  condicionCumplida = hasValue(valor) && valor > condicion.valor_numerico;
                  break;
                case '<':
                  condicionCumplida = hasValue(valor) && valor < condicion.valor_numerico;
                  break;
                case '=':
                  condicionCumplida =
                    hasValue(valor) && (valor === condicion.valor_numerico || valor === condicion.valor_boolean || valor === condicion.valor_string);
                  break;
                case 'incluye':
                  if (hasValue(valor)) {
                    if (valor instanceof Object) {
                      const valor2 = valor as ValorOpcionMultiple;
                      const valoresRespuesta = valor2.seleccion as string[];
                      const valoresCondicion = condicion.valor_opcion as firebase.firestore.DocumentReference[];
                      condicionCumplida = valoresCondicion.filter((vc) => valoresRespuesta.includes(vc.id)).length > 0;
                    }
                  }

                  break;
              }
              if (condicion.negación) {
                condicionCumplida = !condicionCumplida;
              }
              return condicionCumplida;
            };

            const noObligatoriasCumplidas =
              condicionesNoObligatorias.length < 1
                ? true
                : condicionesNoObligatorias.map(seCumple).reduce(
                    (acc, value) => acc || value,
                    false
                  );
            const obligatoriasCumplidas = condicionesObligatorias.map(seCumple).reduce(
              (acc, value) => acc && value,
              true
            );

            const todasLasCondicionesCumplidas = noObligatoriasCumplidas && obligatoriasCumplidas;
            if (opciones) {
              pregunta.opciones = opciones.map((o) => o.data());
            }

            return { mostrar: todasLasCondicionesCumplidas, pregunta: pregunta as Pregunta };
          }

          if (opciones) {
            pregunta.opciones = opciones.map((o) => o.data());
          }

          return { mostrar: true, pregunta: pregunta as Pregunta & firebase.firestore.DocumentData };
        })
      ).then((preguntasConVisibilidad) => {
        //SideEfect calculate if section is complete
        setPreguntasConVisibilidad(preguntasConVisibilidad);
        setLoaded(true);
        const preguntasVisiblesSinRespuesta = preguntasConVisibilidad
          .filter((p) => p.mostrar)
          .filter(p=> p.pregunta.seccionUI!=="subpregunta")
          .filter(p=> p.pregunta.tipo!== "tabla-estática")
          .filter(p=> p.pregunta.tipo!== "número-múltiple")
          .filter((p) => getRespuestaById(p.pregunta.id, declaracionSnap)?.valor === undefined);
        const update = {} as any;
        const nuevoEstadoSeccion = preguntasVisiblesSinRespuesta.length > 0 ? 'comenzado' : 'completado';
        update[`${seccionUI}`] = nuevoEstadoSeccion;

        const declaracion = declaracionSnap?.data() as Declaracion | undefined;
        if(declaracion){
          const estado = declaracion.estado;
          if(estado === 'no-iniciada'){
            const nuevoEstado : Estado = 'iniciada';
            update['estado'] = nuevoEstado;
          }
  
          const estados : {[key: string]: EstadoSeccion} = {
            costos:declaracion.costos,
            deducciones: declaracion.deducciones,
            patrimonio: declaracion.patrimonio,
            perfil:declaracion.perfil,
            ['rentas-exentas']:declaracion['rentas-exentas'],
            ingresos: declaracion.ingresos
          } 

          estados[seccionUI] = nuevoEstadoSeccion;
          const completada = Object.values(estados).reduce( (acc,s) => acc && s==='completado' , true);
          if(completada){
            const nuevoEstado : Estado = 'completada';
            update['estado'] = nuevoEstado;
          }

        }


        firebase.firestore().doc(`/usuarios/${user?.uid}/declaraciones/${declaracionId}/`).update(update);
      });
    }
  }, [preguntas, declaracionSnap, declaracionId, seccionUI, user]);

  const getRespuesta = <R extends Respuesta>(p: Pregunta, declaracionSnap: DeclaracioncionSnap) => {
    const declaracion = declaracionSnap?.data();
    const respuestas = declaracion?.respuestas;
    return respuestas?.[p.id] as R;
  };

  const getRespuestaById = (id: string, declaracionRef: firestore.DocumentSnapshot<firestore.DocumentData>) => {
    const respuestasData = declaracionRef.data()?.respuestas;
    return respuestasData?.[id];
  };

  const campoSiNo = (pregunta: PreguntaSiNo, declaracion: DeclaracioncionSnap) => {
    const respuesta = getRespuesta<RespuestaSiNo>(pregunta, declaracion);
    const onSiNoChanged = (v: boolean | undefined) => {
      respuesta.valor = v;
      updateRespuesta(respuesta);
    };

    return <SiNo value={respuesta?.valor} onValueChanged={onSiNoChanged} />;
  };

  const campoSiNoNúmero = (pregunta: PreguntaNumero, declaracion: DeclaracioncionSnap) => {
    const respuesta = getRespuesta<RespuestaSiNoNumero>(pregunta, declaracion);
    const onSiNoChanged = (v: SiNoNumeroValue) => {
      respuesta.valor = { siNo: v.siNo !== undefined ? v.siNo : false, numero: v.numero };
      updateRespuesta(respuesta);
    };

    return <SiNoNumero value={respuesta?.valor} onValueChanged={onSiNoChanged} />;
  };

  const campoTextoLibre = (pregunta: PreguntaTextoLibre, declaracion: DeclaracioncionSnap) => {
    const respuesta = getRespuesta<RespuestaTextoLibre>(pregunta, declaracion);
    const onTextChanges = (newValue: string) => {
      respuesta.valor = newValue;
      updateRespuesta(respuesta);
    };

    return <TextoLibre value={respuesta?.valor} onValueChanged={onTextChanges} />;
  };

  const numericoMultiple = (
    pregunta: PreguntaMultipleValorNumerico,
    declaracion: DeclaracioncionSnap,
    className: string
  ) => {
    const respuesta = getRespuesta<RespuestanNumeroMultiple>(pregunta, declaracion);
    const onTextChanges = (newValue: NumericoMultipleValue | undefined) => {
      if (respuesta && newValue) {
        respuesta.valor = newValue;
        updateRespuesta(respuesta);
      }
    };

    return (
      <NumericoMultiple
        value={respuesta.valor}
        pregunta={pregunta}
        onValueChanged={onTextChanges}
        className={className}
      />
    );
  };

  const getTablaEstatica = (pregunta: TablaEstatica<'número'>, respuestas: DeclaracioncionSnap, className: string) => {
    return <>
    
    <Tabla pregunta={pregunta} className={className} definicionId={definicionId} declaracionId={declaracionId} userId={user?.uid} />
    
    </>;
  };
  const getCampoOpcionUnica = (pregunta: PreguntaOpcion, declaracion: DeclaracioncionSnap) => {
    const respuesta = getRespuesta<RespuestaOpcionUnica>(pregunta, declaracion);
    const onInputFieldChange = (event: React.ChangeEvent<FormControlElement>) => {
      respuesta.valor = event.currentTarget.value;
      updateRespuesta(respuesta);
    };
    return <OpcionUnica valor={respuesta?.valor} onValueChanged={onInputFieldChange} opciones={pregunta.opciones} />;
  };

  const getCampoOpcionMultiple = (pregunta: PreguntaOpcion, declaracion: DeclaracioncionSnap) => {
    const respuesta = getRespuesta<RespuestaOpcionMultiple>(pregunta, declaracion);
    const onInputFieldChange: OnSelectionChanged = (seleccion: string[], valores: { [key: string]: any } | null) => {
      respuesta.valor = {
        seleccion: seleccion || [],
        valores: valores || {}
      };
      updateRespuesta(respuesta);
    };

    return (
      <OpcionMultiple
        valor={respuesta?.valor}
        onSelectionChanged={onInputFieldChange}
        opciones={pregunta.opciones}
        tieneValores={pregunta.tieneValores || false}
      />
    );
  };

  type DeclaracioncionSnap = firestore.DocumentSnapshot<firestore.DocumentData & Declaracion>;

  const getInputField = (pregunta: PreguntaSimple, declaracion: DeclaracioncionSnap) => {
    if (esPreguntaSiNo(pregunta)) {
      return campoSiNo(pregunta, declaracion);
    }
    if (esPreguntaNumero(pregunta)) {
      return campoSiNoNúmero(pregunta, declaracion);
    }
    if (esPreguntaOpcion(pregunta)) {
      switch (pregunta.tipo) {
        case 'opción-única':
          return getCampoOpcionUnica(pregunta, declaracion);
        case 'opción-múltiple':
          return getCampoOpcionMultiple(pregunta, declaracion);
      }
    }
    if (esPreguntaTextoLibre(pregunta)) {
      return campoTextoLibre(pregunta, declaracion);
    }
  };

  const getFormFields = (preguntasConVisibilidad: PreguntaConVisibilidad[], declaracion: DeclaracioncionSnap) => {
    return preguntasConVisibilidad.map(({ mostrar, pregunta }) => {
      let className = '';
      if (!mostrar) {
        className += 'preguntaEscondida';
      }

      return (
        <React.Fragment key={pregunta.id}>
          {esPreguntaMultipleNumerica(pregunta) ? (
            numericoMultiple(pregunta, declaracion, className)
          ) : esTablaEstatica(pregunta) ? (
            getTablaEstatica(pregunta, declaracion, className)
          ) : (
            <Form.Group as={Row} controlId={pregunta.id} className={className}>
              <Form.Label column sm="6">
                {pregunta.texto}
              </Form.Label>
              <Col sm="6">
                <div className="d-flex justify-content-end">
                  {getInputField(pregunta as PreguntaSimple, declaracion)}
                  &nbsp;
                  <InfoToolTip id={pregunta.id} ayuda={pregunta.ayuda} />
                </div>
              </Col>
            </Form.Group>
          )}
        </React.Fragment>
      );
    });
  };

  if(!(preguntasConVisibilidad && declaracionSnap)){
    setLoaded(false)
  }

  return (
    <div>

      {!(preguntasConVisibilidad && declaracionSnap) && <Loader />}
      {preguntasConVisibilidad && declaracionSnap && (
        <Form>{getFormFields(preguntasConVisibilidad, declaracionSnap as DeclaracioncionSnap)}</Form>
      )}
      {errorCargandoPreguntas && errorCargandoPreguntas.message}
    </div>
  );
};

export default Plantilla;
