import React from "react";
import '../styles/Footer.css';

const Footer: React.FC = (props: any) => {
    return (
        <footer id="footer">
            <p id="p-footer">&copy; SimplyPro 2023</p>
        </footer>
    )
}

export { Footer };
