import React from "react"
import { Button } from 'react-bootstrap';
import '../styles/Inicio.css';
import Image from 'react-bootstrap/Image';
import Logo from '../assets/logolblanca.png';
import pctrans from '../assets/pctrans.svg';
import peggy from '../assets/señora.png';
import time from '../assets/60mins.png';
import security from '../assets/sello.png';
import { Navigation } from '../components/Navigation';
import { Cita } from "../components/Cita";
import { analytics } from "../firebase";
import sino from '../assets/sino.png';
import { auth } from "../firebase";


const Inicio: React.FC = (props: any) => {
    // const username = firebase.auth().currentUser?.displayName
    // const history = useHistory();
    // const clickToAction = () => {
    //     history.push("contenidoimpuestos")
    // }
    analytics.logEvent('landing_page', { name: 'loquesea'});
    const currentUser = auth.currentUser;

    return (
      <>
        <Navigation />
        <div id="contenedor">
          <div id="c-div">
            <h1 id="c-h">Declara renta de manera rápida, confiable y completamente gratis.</h1>
            <p id="c-p">Realiza todo el proceso en línea y sin intermediarios</p>
            <Button id="loginBtn" className="btn-margin" href="/contenidoimpuestos">
              Conoce si debes declarar renta y tus plazos
            </Button>
            <Button id="loginBtn" className="btn-margin" href="/ingresar" style={{marginTop: "32px"}}>
              Haz tu declaración
            </Button>
            :
          </div>
          <Image src={pctrans} id="pc" />
        </div>
        <div id="section">
          <img src={sino} alt="sino" id="sino" />
          <p id="p-sec2">
            Con SimplyPro, sólo debes responder una serie de preguntas sencillas y en cuestión de minutos tendrás lista
            tú declaración
          </p>
        </div>
        <div id="section-3">
          <h1 id="ventajas">
            Ventajas de hacer tú declaración de renta con <img src={Logo} alt="logo" className="logo-text" />
          </h1>
          <section id="boxes">
            <div id="box">
              <img src={peggy} alt="cerdito" id="cerdito" />
              <h2 className="box-text">Ahorras</h2>
              <p className="p-box">¡Haz tu declaración completamente gratis!</p>
            </div>
            <div id="box">
              <img src={time} alt="reloj" id="reloj" />
              <h2 className="box-text">Más rápido</h2>
              <p className="p-box">¡60 minutos o menos!</p>
            </div>
            <div id="box">
              <img src={security} id="candado" alt="seguridad" />
              <h2 className="box-text">Más seguro</h2>
              <p className="p-box">¡Controla la información que presentas sin errores!</p>
            </div>
          </section>
        </div>
        <div id="section-5">
          <div id="text-container">
            <h1 id="texto-content">Revisa los requisitos para declarar renta y tú fecha límite</h1>
            <Button id="begin-btn" className="btn-margin" href="/contenidoimpuestos">
              Comienza aquí
            </Button>
            <p id="parr-content">
              Para que estés obligado a declarar tienes que estar dentro de los topes establecidos por la DIAN año a
              año, recuerda que este 2023 se estará declarando el año gravable 2022
            </p>
          </div>
        </div>
        <Cita />
      </>
    );
}

export default Inicio;
