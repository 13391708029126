import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Inicio from './pages/Inicio'
import Ingresar from './pages/Ingresar';
import { ComoFunciona } from './pages/ComoFunciona';
import { ContenidoImpuestos } from './pages/ContenidoImpuestos';
import Declaraciones from './pages/Declaraciones';
import PreguntasFrecuentes from './pages/PreguntasFrecuentes';
import { Blog } from './pages/Blog';
import { Contacto } from './pages/Contacto';
import { Footer } from './components/Footer';
import 'holderjs';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// fontawesome
import initFontAwesome from "./utils/initFontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import './styles/App.css';
import { RUT } from './pages/blog/RUT';
import { DIANUsuario } from './pages/blog/DIANUsusario';
import { DIANFirma } from './pages/blog/DIANFirma';
import FloatingWhatsApp from 'react-floating-whatsapp'
import logo from './assets/avatar.jpeg';
import { GuiaDian } from './pages/blog/GuiaDIAN';





initFontAwesome();
library.add(fas)

const App: React.FC = (props: any) => {
  return (
    <>
    <Router>
      <Container fluid className="principal-container">
          <Switch>
            <Route path="/inicio">
              <Inicio />
            </Route>
            <Route path="/ingresar">
              <Ingresar />
            </Route>
            <Route path="/declaraciones">
              <Declaraciones />
            </Route>
            <Route path="/comofunciona">
              <ComoFunciona />
            </Route>
            <Route path="/preguntasfrecuentes">
              <PreguntasFrecuentes />
            </Route>
            <Route path="/blog/RUT">
              <RUT />
            </Route>
            <Route path="/blog/usuarioDIAN">
              <DIANUsuario />
            </Route>
            <Route path="/blog/firmaDIAN">
              <DIANFirma />
            </Route>

            <Route path="/blog/GuiaDian">
              <GuiaDian />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>

            <Route path="/contacto">
              <Contacto />
            </Route>
            <Route path="/contenidoimpuestos">
              <ContenidoImpuestos />
            </Route>
            <Route exact path="/">
              <Redirect to="/inicio" />
            </Route>
          </Switch>
      </Container>
      <Footer />
      <FloatingWhatsApp
       phoneNumber='+573204663581'
       accountName='SimplyPro'
       avatar={logo}
       statusMessage='Normalmente responde en menos de 1 hr'
       chatMessage='Hola soy Sara, gracias por comunicarte con Simplypro. ¿Cómo podemos ayudarte?'
       

    />

    </Router>

    </>
  );
}

export default App;
