import React, { useEffect, useState } from 'react';
import firebase, { firestore } from 'firebase';
import { Col, Form, Row, Tabs } from 'react-bootstrap';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { useCollectionData, useDocument } from 'react-firebase-hooks/firestore';
import {
  Respuesta,
  SubpreguntaTablaEstatica,
  TablaEstatica,
  ValorNumericoMultiple,
  ValorOpcionMultiple,
  ValorSiNoNumero
} from 'simplypro-db';
import cellEditFactory from 'react-bootstrap-table2-editor';
import InfoToolTip from './InfoTooltip';
import { Declaracion, Respuestas } from '../../dbTypes/Declaracione';

type DocumenDataQuerySnap = firestore.QuerySnapshot<firestore.DocumentData>;
export interface TablaProps {
  pregunta: TablaEstatica<'número'>;
  declaracionId: string;
  definicionId: string;
  userId?: string;
  className?: string;
}

interface TableRow {
  id: string;
  respuesta: Respuesta;
  [columnaTextoOValor: string]:
    | string
    | number
    | boolean
    | ValorOpcionMultiple
    | ValorNumericoMultiple
    | ValorSiNoNumero
    | Respuesta
    | undefined;
}

const Tabla: React.FC<TablaProps> = ({ pregunta, declaracionId, definicionId, userId, className }) => {
  const numberFormat = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
  const tableColumns = pregunta.columnas?.map((c) => {
    const result = { dataField: c.id, text: c.titulo, editable: c.tipoDeColumna !== 'estática' } as ColumnDescription;

    if (c.tipoDeColumna === 'numero') {
      result.align = 'right';
      result.formatter = (cell, row) => {
        const formatedNumber = numberFormat.format(cell);
        return <>{formatedNumber}</>;
      };
    }

    result.headerStyle = { width: c.ancho + '%', color: '#fff', textAlign: 'center', backgroundColor: '#0e6ba8' };
    return result;
  });

  const [preguntas, preguntasLoaded, error] = useCollectionData<SubpreguntaTablaEstatica>(
    firebase
      .firestore()
      .collection(`/definiciones/${definicionId}/preguntas`)
      .where('preguntaMadre', '==', `${pregunta.id}`)
      .orderBy('indice')
  );
  const [declaracion, cargandoRespuestas, errorCargandoRespuestas] = useDocument(
    firebase.firestore().doc(`/usuarios/${userId}/declaraciones/${declaracionId}`)
  );

  const [tableEntries, setTableEntries] = useState<TableRow[] | undefined>();

  useEffect(() => {
    console.log(definicionId);
    console.log(error);
    console.log(preguntasLoaded);
    if (preguntas) {
      const tableEntries = preguntas?.map((p) => {
        const result = {} as TableRow;
        result[p.columnaTexto] = p.texto;
        result['id'] = p.id;
        for (const cId in p.columnasAdicionales) {
          const valor = p.columnasAdicionales[cId];
          result[cId] = valor;
        }
        if (declaracion) {
          const respuesta = getRespuesta(p, declaracion);

          result[p.columnaValor] = respuesta.valor ? respuesta.valor : 0;
          result.respuesta = respuesta;
        }
        return result;
      });
      setTableEntries(tableEntries);
    }
  }, [preguntas, declaracion]);

  const getRespuesta = <R extends Respuesta>(
    p: SubpreguntaTablaEstatica,
    declaracion: firestore.DocumentSnapshot<firestore.DocumentData>
  ) => {
    const respuestas: Respuestas = (declaracion.data() as Declaracion).respuestas;
    const respuesta = respuestas[p.id];

    if (!respuesta) {
      throw Error('Respuesta no encontrada');
    }
    return respuesta;
  };

  const rowStyle = (row: any, rowIndex: number) => {
    row.index = rowIndex;
    const style: any = {};
    style.backgroundColor = 'transparent';
    return style;
  };

  const onCellSave = (oldValue: any, newValue: string, row: any, column: ColumnDescription<any> & any) => {
    row.valor = Number.parseInt(newValue);
    const respuesta = row.respuesta;
    respuesta.valor = row.valor;
    const key = `respuestas.${respuesta.id}`;
    const update = { [key]: respuesta };
    declaracion?.ref.update(update);
  };

  return (
    <>
      <Col sm="12">

          <Form.Label className="d-flex" column sm="12">
            {/* <div className="d-flex "> */}
            <h5>{pregunta.texto}</h5>
            &nbsp;
            <InfoToolTip id={pregunta.id} ayuda={pregunta.ayuda} />
            {/* </div> */}
            &nbsp;
          </Form.Label>

      </Col>
      <Col sm="12">
        {' '}
        {pregunta.id === 'I21' && (
          <p style={{ fontStyle: "italic"}}>Si tienes más de un certificado, suma los valores de cada casilla e ingresa el total.</p>
        )}
      </Col>

      <Col sm="12" className={className}>
        {tableColumns && tableEntries && (
          <BootstrapTable
            keyField="id"
            data={tableEntries}
            columns={tableColumns}
            rowStyle={rowStyle}
            cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: onCellSave })}
          />
        )}
      </Col>
      <Col sm="12" className={className}>
        <div className="d-flex justify-content-end">{/* Total: {numberFormat.format(total)} */}</div>
      </Col>
    </>
  );
};

const TablaMultiple = ({ pregunta }: TablaProps) => {
  return <Tabs></Tabs>;
};

export default Tabla;
