import React from 'react';

import { Navigation } from '../../components/Navigation';
import { Container } from 'react-bootstrap';
import YoutubeEmbed from './Youtube';

const DIANFirma: React.FC = (props: any) => {
  return (
    <>
      <Navigation />
      <Container>
        <h1>¿Como generar la Firma Electrónica?</h1>

        <br />
        <p>
          Este video te explica como generar la Firma Electrónica paso a paso directamente en el portal de la DIAN.
          Recuerda primero asegurarte de tener tu <a className="link"    href="/blog/RUT" target="_">RUT actualizado</a>  y tener habilitada tu cuenta de
          <a className="link"    href="/blog/usuarioDIAN" target="_"> usuario de la DIAN</a>.
        </p>
        <YoutubeEmbed embedId="bGCTowxqSGc"></YoutubeEmbed>
      </Container>
    </>
  );
};

export { DIANFirma };
