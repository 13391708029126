import React from "react";
import { PopupButton } from "react-calendly";
import '../styles/Calendly.css';

const Calendly = () => {
    return (
        <>
            <div className="calend">
                <PopupButton 
                    url="https://calendly.com/luis-a-barroso/30min?month=2023-03" 
                    rootElement={document.getElementById("root") || document.body}
                    text="Agenda tú cita"
                    className="cal-btn"
                />
            </div>
        </>
    )
}

export { Calendly }