import React from "react";
import { Cita } from "../components/Cita";
import { MyForm } from "../components/MyForm";
import { Navigation } from '../components/Navigation';
import contact from '../assets/contacto.png';
import '../styles/Contacto.css';

const Contacto: React.FC = (props: any) => {


    return (
        <> 
            <Navigation />
            <div className="forms-container">
                <div className="ti-container">
                    <h1 className="title">Puedes escribirnos o agendar una cita y nos comunicarémos contigo</h1>
                    <img src={contact} alt="contact" className="img-contact" />
                </div>
                <MyForm />
            </div>
            <Cita />
        </>
    );
}

export { Contacto };