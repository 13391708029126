import React from "react"
import { Button } from "react-bootstrap"

interface SiNoProperties{
    value?:boolean
    onValueChanged?: (newValue:boolean|undefined) => void
}

const SiNo : React.FC<SiNoProperties> = ({value,onValueChanged}) =>{

    return (
        <div className="si-no-container">
        <Button variant={value === true ? "primary" : "outline-primary"} onClick={  e =>  onValueChanged && onValueChanged(true) }>Si</Button>
        <Button variant={value === false ? "secondary" : "outline-secondary"} onClick={  e =>  onValueChanged && onValueChanged(false) }>No</Button>
    </div>
    )
}

export default SiNo;
