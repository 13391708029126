import React, { useState } from 'react';
import '../styles/Fecha.css';

const Fecha: React.FC<{onDigitosCedulaInput: (value:string)=> void}> = ({onDigitosCedulaInput}) => {
    const [inputValue, setInputValue] = useState('');
    const [fechaLimite, setFechaLimite] = useState('');


    const getFechLimite = (newValue:string) =>{
        if (newValue === '01' || newValue === '02') {
            return '09 de agosto de 2023';
            } else if (newValue === '03' || newValue === '04') {
            return '10 de agosto de 2023';
            } else if (newValue === '05' || newValue === '06') {
            return '11 de agosto de 2023';
            } else if (newValue === '07' || newValue === '08') {
            return '14 de agosto de 2023';
            } else if (newValue === '09' || newValue === '10') {
            return '15 de agosto de 2023';
            } else if (newValue === '11' || newValue === '12') {
            return '16 de agosto de 2023';
            } else if (newValue === '13' || newValue === '14') {
            return '17 de agosto de 2023';
            } else if (newValue === '15' || newValue === '16') {
            return '18 de agosto de 2023';
            } else if (newValue === '17' || newValue === '18') {
            return '22 de agosto de 2023';
            } else if (newValue === '19' || newValue === '20') {
            return '23 de agosto de 2023';
            } else if (newValue === '21' || newValue === '22') {
            return '24 de agosto de 2023';
            } else if (newValue === '23' || newValue === '24') {
            return '25 de agosto de 2023';
            } else if (newValue === '25' || newValue === '26') {
            return '28 de agosto de 2023';
            } else if (newValue === '27' || newValue === '28') {
            return '29 de agosto de 2023';
            } else if (newValue === '29' || newValue === '30') {
            return '30 de agosto de 2023';
            } else if (newValue === '31' || newValue === '32') {
            return '31 de agosto de 2023';
            } else if (newValue === '33' || newValue === '34') {
            return '01 de septiembre de 2023';
            } else if (newValue === '35' || newValue === '36') {
            return '04 de septiembre de 2023';
            } else if (newValue === '37' || newValue === '38') {
            return '05 de septiembre de 2023';
            } else if (newValue === '39' || newValue === '40') {
            return '06 de septiembre de 2023';
            } else if (newValue === '41' || newValue === '42') {
            return '07 de septiembre de 2023';
            } else if (newValue === '43' || newValue === '44') {
            return '08 de septiembre de 2023';
            } else if (newValue === '45' || newValue === '46') {
            return '11 de septiembre de 2023';
            } else if (newValue === '47' || newValue === '48') {
            return '12 de septiembre de 2023';
            } else if (newValue === '49' || newValue === '50') {
            return '13 de septiembre de 2023';
            } else if (newValue === '51' || newValue === '52') {
            return '14 de septiembre de 2023';
            } else if (newValue === '53' || newValue === '54') {
            return '15 de septiembre de 2023';
            } else if (newValue === '55' || newValue === '56') {
            return '18 de septiembre de 2023';
            } else if (newValue === '57' || newValue === '58') {
            return '19 de septiembre de 2023';
            } else if (newValue === '59' || newValue === '60') {
            return '20 de septiembre de 2023';
            } else if (newValue === '61' || newValue === '62') {
            return '21 de septiembre de 2023';
            } else if (newValue === '63' || newValue === '64') {
            return '22 de septiembre de 2023';
            } else if (newValue === '65' || newValue === '66') {
            return '25 de septiembre de 2023';
            } else if (newValue === '67' || newValue === '68') {
            return '26 de septiembre de 2023';
            } else if (newValue === '69' || newValue === '70') {
            return '27 de septiembre de 2023';
            } else if (newValue === '71' || newValue === '72') {
            return '28 de septiembre de 2023';
            } else if (newValue === '73' || newValue === '74') {
            return '29 de septiembre de 2023';
            } else if (newValue === '75' || newValue === '76') {
            return '02 de octubre de 2023';
            } else if (newValue === '77' || newValue === '78') {
            return '03 de octubre de 2023';
            } else if (newValue === '79' || newValue === '80') {
            return '04 de octubre de 2023';
            } else if (newValue === '81' || newValue === '82') {
            return '05 de octubre de 2023';
            } else if (newValue === '83' || newValue === '84') {
            return '06 de octubre de 2023';
            } else if (newValue === '85' || newValue === '86') {
            return '09 de octubre de 2023';
            } else if (newValue === '87' || newValue === '88') {
            return '10 de octubre de 2023';
            } else if (newValue === '89' || newValue === '90') {
            return '11 de octubre de 2023';
            } else if (newValue === '91' || newValue === '92') {
            return '12 de octubre de 2023';
            } else if (newValue === '93' || newValue === '94') {
            return '13 de octubre de 2023';
            } else if (newValue === '95' || newValue === '96') {
            return '17 de octubre de 2023';
            } else if (newValue === '97' || newValue === '98') {
            return '18 de octubre de 2023';
            } else if (newValue === '99' || newValue === '00') {
            return '19 de octubre de 2023';
            }
            return null;
    }

    function numeroCedula(event: React.ChangeEvent<HTMLInputElement>): void {
        const newValue = event.target.value;
        setInputValue(newValue);
        const fechaLimite = getFechLimite(newValue);
        if(fechaLimite){
            setFechaLimite(fechaLimite);
            onDigitosCedulaInput(newValue);
        }
    }
    return (
        <div id='dt-container'>
            <label className="inputnumber" htmlFor='inputNumber'>Ingresa los dos últimos dígitos de tú cédula:</label>
            <input autoFocus type='text' id='inputNumber' style={{borderRadius: "20%", minWidth:"60px"}} value={inputValue} onChange={numeroCedula} min='1' maxLength={2} />
            {fechaLimite && (
                <div>
                <p className='date'>Tienes plazo para declarar renta hasta el {fechaLimite}</p>
                </div>
            )}
    </div>
    );
};

export { Fecha };
