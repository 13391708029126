import React from 'react';
import { WorkInProgress } from '../components/WorkInProgress';
import { Navigation } from '../components/Navigation';
import { Button, Card, CardDeck, Col, Container, Row } from 'react-bootstrap';

const Blog: React.FC = (props: any) => {
  return (
    <>
      <Navigation />
      <Container>
        <Row>
          <Col lg="12">
            <h1>Ayudas</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <h2>¿Como sacar el RUT?</h2>
            <Card.Text>
              En Colombia, el Registro Único Tributario (RUT) es un documento fundamental para las personas naturales
              que desean realizar actividades económicas, así como para cumplir con sus obligaciones tributarias.
              Obtener el RUT es un proceso relativamente sencillo y necesario para aquellos que deseen emprender,
              trabajar de forma independiente o realizar transacciones comerciales en el país. En este blog, te guiaré a
              través de los pasos necesarios para obtener tu RUT como persona natural en Colombia.
            </Card.Text>

            <Button variant="primary" href="/blog/RUT" target="_">
              Leer más
            </Button>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col lg="12">
            <h2>¿Como crear y habilitar tu cuenta de usuario en la DIAN?</h2>
            <Card.Text>
              🙌 Tú puedes realizar las diferentes gestiones y transacciones como persona natural o jurídica a través
              del portal transaccional de la DIAN. Aquí te contamos cuáles son los pasos para habilitar tu cuenta de
              usuario registrado y acceder a estos servicios en línea o, en caso de olvido, recuperar tu contraseña de
              acceso. 😃

              Este video te explica como puedes hacerlo…
            </Card.Text>
            <Button variant="primary" href="/blog/usuarioDIAN" target="_">
              Ver el video
            </Button>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col lg="12">
            <h2>¿Como generar la Firma Electrónica?</h2>
            <Card.Text>
            Este video te explica como generar la Firma Electrónica paso a paso directamente en el portal de la DIAN.
          Recuerda primero asegurarte de tener tu <a className="link"  href="/blog/RUT" target="_">RUT actualizado</a>  y tener habilitada tu cuenta de
          <a className="link"   href="/blog/usuarioDIAN" target="_"> usuario de la DIAN</a>.
            </Card.Text>
            <Button variant="primary" href="/blog/firmaDIAN" target="_">
              Ver el video
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { Blog };
