import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const  firebaseConfig = {
  apiKey: "AIzaSyBwLxNZJ37bT57cyZLtFIoS2K6BCiCx7qU",
  authDomain: "simplypro-prod.firebaseapp.com",
  projectId: "simplypro-prod",
  storageBucket: "simplypro-prod.appspot.com",
  messagingSenderId: "146887632788",
  appId: "1:146887632788:web:b5fa9588609a930b64d181",
  measurementId: "G-7FY454EXSE"
}




firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics()