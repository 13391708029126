import React, { FormEventHandler, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import firebase from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { Button, Card, CardDeck, Container, Form, Modal } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { Declaracion as DeclaracionDeRenta, Estado } from '../dbTypes/Declaracione';
import Declaracion from '../components/declaracion/Declaracion';
import { Loader } from '../components/Loader';
import { Navigation } from '../components/Navigation';
import '../styles/Declaraciones.css';
import titleLogo from '../assets/Logoblanco.png';
import checkwhite from '../assets/Logoblanco.png';
import InfoToolTip from '../components/widgets/InfoTooltip';


interface Definicion {
    titulo: string
    id:string
}

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const MensajeDeclaracion = ({estado}:{estado: Estado})=>{
    switch(estado){
        case 'no-iniciada':
            return (
                <p></p>
            )
    }
}
const nombrePorDefecto = 'Declaración 2022';
const definicionIdPorDefecto = 'declaracion_2022'
const Declaraciones: React.FC = (props: any) => {

    //const [declaraciones, setDeclaraciones] = useState<DeclaracionDeRenta[]>();
    const [user] = useAuthState(firebase.auth());
    const [declaraciones, loadingDeclaraciones, errorDeclaraciones] = useCollection(firebase.firestore().collection(`/usuarios/${user?.uid}/declaraciones`));
    const [definiciones]=  useCollectionDataOnce<Definicion>(firebase.firestore().collection('/definiciones').where("activa", "==", true))
    const [nombre, setNombre] = useState<string>(nombrePorDefecto)
    const [definicionId, setDefinicionId] = useState<string>(definicionIdPorDefecto)
    
    const { path, url } = useRouteMatch();
    const [show, setShow] = useState(false);

    const submitNewDeclaracion : FormEventHandler<HTMLObjectElement> = async (e) => {
        e.preventDefault()
        let _definicionId = definicionId;
        if(!_definicionId){
            _definicionId = definicionIdPorDefecto
        }
        const newDeclaracion = {
            titulo: nombre,
            definicionRef: firebase.firestore().doc(`/definiciones/${_definicionId}`) 
        }
        const docRef = await firebase.firestore().collection(`/usuarios/${user?.uid}/declaraciones`).add(newDeclaracion)
        console.log(docRef.id)

        setDefinicionId(definicionIdPorDefecto)
        setNombre(nombrePorDefecto)
        setShow(false);
    }

    const cancelAgregar = () => {
        setDefinicionId(definicionIdPorDefecto)
        setNombre(nombrePorDefecto)
        setShow(false);
    }

    const getButton = (estado: Estado, id: string) => {
    
        const getButtonText = (estado:Estado)  =>{
        switch(estado){
            case 'no-iniciada':
                return 'Comenzar';
            case 'iniciada':
                return 'Continuar';   
            case 'completada':
                return 'Revisar'     
        }
    }

        return (
            <LinkContainer to={`${url}/${id}`}>
                <Button variant="primary">{getButtonText(estado)}</Button>
            </LinkContainer>
        );
    }

    const addDeclaracion : React.MouseEventHandler= (e)=>{
        setShow(true);
    }

    const onTipoChanged =  (e: React.ChangeEvent<FormControlElement>) => {
        const definicionId = e.currentTarget.value;
        setDefinicionId(definicionId)
    }

    const onNombreChanged = (e: React.ChangeEvent<FormControlElement>)=>{
        const newValue = e.currentTarget.value;
        setNombre(newValue)
    }

    const eliminarDeclaracion = async (id: string) => {
        try {
            await firebase.firestore().doc(`/usuarios/${user?.uid}/declaraciones/${id}`).delete();
        } catch (error) {
            console.error('Error eliminando declaracion:', error);
        }
    }

    const ayudaAñoGravable =`
    Recuerda que este año debes declarar renta del 2022. 
    Si este es tu caso, selecciona la opción “Año Gravable 2022”.\n

    Si no has presentado aun tu declaración de renta del periodo 
    pasado (2021) o si deseas volver a hacer tu declaración de renta 
    del año pasado, lo puedes hacer escogiendo la opción “Año 2021”.
    `


    
    return (
      <>
        <Navigation />
        <Container>
          <Switch>
            <Route exact path={path}>
              <div id="declaracion-container">
                {(loadingDeclaraciones && <Loader />) ||
                  ((!declaraciones || declaraciones.docs.length < 1) && (
                    <>
                      <div id="welcome-container">
                        <p id="welcome-title">
                          Bienvenido a SimplyPro <img src={titleLogo} id="title-logo" alt="logo" />
                        </p>
                        {/* <p id='welcome-message'>¡Gracias por confiar en nosotros! </p>
                                    <p id='welcome-message2'>Este 20 de Julio te contaremos como puedes independizarte y hacer tu declaración de renta tu mismo, sin intermediarios, de manera fácil, confiable, ¡y totalmente gratis!</p> */}
                        <p id="welcome-message">
                          ¡Gracias por confiar en nosotros! Ahora es momento de realizar tú declaración de renta de
                          manera rápida y confiable
                        </p>
                        <p id="welcome-message2">
                          El proceso inicia con una serie de preguntas que permitirán definir tú perfil y dependiendo de
                          tus respuestas tendrás que diligenciar únicamente la información que es relevante en las
                          diferentes secciones:
                        </p>
                        <ul className="list-welcome">
                          <li className="list-item-welcome">
                            <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                            Patrimonio
                          </li>
                          <li className="list-item-welcome">
                            <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                            Ingresos
                          </li>
                          <li className="list-item-welcome">
                            <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                            Costos y gastos
                          </li>
                          <li className="list-item-welcome">
                            <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                            Deducciones
                          </li>
                          <li className="list-item-welcome">
                            <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                            Rentas Exentas
                          </li>
                          <li className="list-item-welcome">
                            <img src={checkwhite} alt="logocheck" className="check-logo-white" />
                            Información Adicional
                          </li>
                        </ul>
                      </div>
                      <p id="welcome-message3">
                        Cuando completes todas las secciones únicamente debes presionar en Cálculos y ¡listo! tendrás el
                        valor que debes diligenciar en cada una de las casillas del Formulario 210, junto con un
                        instructivo que debes descargar y en el cual encontrarás toda la información necesaria para que
                        presentes tú declaración de renta en el sistema de la DIAN
                      </p>
                      <p id="welcome-message4">
                        Bueno, sin más preámbulo ¡dale click al botón que se encuentra debajo para que inicies con tú
                        primera declaración!
                      </p>
                    </>
                  ))}
                <div className="float-left" style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button id="new-declaracion" onClick={addDeclaracion}>
                    Nueva Declaración
                  </Button>

                  <Button id="loginBtn" className="btn-margin" style={{marginLeft: "16px"}} href="/contenidoimpuestos">
              Conoce  tus plazos
            </Button>
                </div>
                <Modal show={show} onHide={cancelAgregar}>
                  <Form onSubmit={submitNewDeclaracion}>
                    <Modal.Header closeButton>
                      <Modal.Title>Nueva Declaración</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form.Group className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control required onChange={onNombreChanged} value={nombre} placeholder="" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Form.Label>Año Gravable</Form.Label>
                          <InfoToolTip className="ayuda-año-gravable" id="año-gravable" ayuda={ayudaAñoGravable} />
                        </div>

                        <Form.Control required as="select" onChange={onTipoChanged} value={definicionId}>
                          {definiciones && definiciones.map((d) => <option value={d.id}>{d.titulo}</option>)}
                        </Form.Control>
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={cancelAgregar}>
                        Cancelar
                      </Button>
                      <Button type="submit" variant="primary">
                        Agregar
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal>
              </div>
              <CardDeck>
                {errorDeclaraciones && <strong>Error: {JSON.stringify(errorDeclaraciones)}</strong>}
                {/* {loadingDeclaraciones && <Loader />} */}
                {/* {!loadingDeclaraciones && (!declaraciones || declaraciones.docs.length < 1) && <span>Aún no tienes declaraciones ¡crea la primera!</span>} */}
                {declaraciones &&
                  declaraciones.docs.map((doc) => {
                    const declaracion = doc.data() as DeclaracionDeRenta;
                    return (
                      <Card key={declaracion.id}>
                        <Card.Header>
                          {declaracion.titulo}
                          <CloseButton className="ml-2" onClick={() => eliminarDeclaracion(doc.id)}>
                            X
                          </CloseButton>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text></Card.Text>
                          {getButton(declaracion.estado, doc.id)}
                        </Card.Body>
                      </Card>
                    );
                  })}
              </CardDeck>
            </Route>
            <Route path={`${path}/:declaracionId/:seccionKey?`}>
              <Declaracion></Declaracion>
            </Route>
          </Switch>
        </Container>
      </>
    );
};

export default Declaraciones;