import React from "react";
import { IconPrefix, IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { UISectionDefinition } from "simplypro-db";
import classNames from "classnames";

const SideBar: React.FC<{declaracion:any,declaracionId:string, seccionKey:string, isOpen?:boolean, labelsVisible?:boolean, toggle: ()=>void, seccionesUI: UISectionDefinition[]}> = ({declaracion, seccionKey, isOpen=true, labelsVisible, seccionesUI, declaracionId}) => {

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <Nav className="flex-column pt-2">
        {seccionesUI.map(s=>{
          let className ="";
          if(declaracion[s.id]==="completado"){
            className += "completado";
          }
          const disabled = declaracion[s.id]===undefined;
          return (
            <Nav.Item key={s.id} className={seccionKey === s.id? "active": ""}  >
            <LinkContainer to={`/declaraciones/${declaracionId}/${s.id}`} >
              <Nav.Link className={className}  disabled={s.id !== 'perfil' &&  !declaracion['docCheck']}>
                <FontAwesomeIcon icon={[s.icono.prefijo as IconPrefix , s.icono.nombre as IconName]} className="mr-2" />
                {labelsVisible && s.titulo}
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
          )   
        })}
          <Nav.Item className={seccionKey === "calculos"? "active": ""}>
            <LinkContainer to={`/declaraciones/${declaracionId}/calculos`} >
              <Nav.Link  disabled={  !declaracion['docCheck']} >
                <FontAwesomeIcon icon={["fas" , "calculator-simple" as IconName]} className="mr-2" />
                {labelsVisible && "Calculos"}
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
      </Nav>
    </div>
  );
}

export default SideBar;