import firebase from "firebase";
import React, { useState } from "react";
import logo from '../assets/logo.png';
import '../styles/MyForm.css';


type FormDataContacto = {
    nombre: string;
    correo: string;
    mensaje: string;
    acepto: boolean;
  };

const MyForm: React.FC = () => {
    const [formData, setFormData] = useState<Partial<FormDataContacto>>({acepto:false});
    const enviarFormulario = firebase.functions().httpsCallable('SendFormularioContacto');
    const [enviado, setEnviado] = useState(false);
    const [enviando, setEnviando] = useState(false);
    const [formSumissionId, setFormSubmissionId]= useState<string>();

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEnviando(true);
        try{
          const result = await enviarFormulario(formData);
          setFormSubmissionId(result.data)
          setEnviando(false);
          setEnviado(true);
        } catch(e){
          console.error('unable to send form data')
          console.error(e);
        }
    
      };
      const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
          setFormData({ ...formData, correo: event.target.value });
        }
      };
      const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
          setFormData({ ...formData, nombre: event.target.value });
        }
      };
      const onMensajeChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.target.validity.valid) {
          setFormData({ ...formData, mensaje: event.target.value });
        }
      };

      const onAceptoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
          setFormData({ ...formData, acepto: event.target.checked });
        }
      };



    return (
        <>
            <div id="form-container">
                <div id="contact-form">
                    {!enviado && <form action="" method="post" onSubmit={handleFormSubmit}>
                        <img src={logo} alt="logo" />
                        <h1 id="heading">Contáctanos para más información</h1>
                            <label htmlFor="name">Nombre:</label>
                                <input type="text" id="name" name="name" required onChange={onNameChange}  />
                            <label htmlFor="email">Correo:</label>
                                <input type="email" id="email" name="email" required  onChange={onEmailChange} />
                            <label htmlFor="message">Mensaje:</label>
                            <textarea id="message" name="message" required onChange={onMensajeChange} />
                            {/* <label>
                                <input type="checkbox" name="terms" required onChange={onAceptoChange} />
                                Acepto los <a href="../tyc.pdf" target="_blank">términos y condiciones</a>
                            </label> */}
                            <button type="submit" value="Enviar" id="send">Envíar</button>
                    </form>}
                    {enviado && <div className='mensaje-enviado'> <h1 id="heading">Gracias por tu mensaje. </h1> </div> }
                </div>
            </div>
        </>
    )
}

export { MyForm };