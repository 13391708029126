import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import { ItemNumericoMultiple, PreguntaMultipleValorNumerico } from "simplypro-db";
import cellEditFactory from 'react-bootstrap-table2-editor';
import InfoToolTip from "./InfoTooltip";

export interface NumericoMultipleValue {
    valor?: number
    items?: ItemNumericoMultiple[]|null
}

interface props {
    onValueChanged: (value?: NumericoMultipleValue) => void
    value?: NumericoMultipleValue,
    pregunta: PreguntaMultipleValorNumerico,
    className: string
}

type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const NumericoMultiple: React.FC<props> = ({ onValueChanged, value, pregunta, className }) => {
    const [multipleActivo, setMultipleActivo] = useState<boolean>(!!value?.items && value.items.length>0);
    const [internalValue, setInternalValue] = useState<NumericoMultipleValue | undefined>(value);
    const [selection, setSelection] = useState<Array<number>>([]);

    const numberFormat = new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0, minimumFractionDigits: 0 });

    const updateValue = (newValue: NumericoMultipleValue) => {
        if(newValue.items && newValue.items.length>0){
            const total = newValue.items.reduce((acc, item) => acc + item.valor, 0);
            newValue.valor=total;
        }
        setInternalValue(newValue);
        onValueChanged(newValue);
    }

    const onNumberChanged = (e: React.ChangeEvent<FormControlElement>) => {
        const inputNumber = e.currentTarget.value.replace(/[^0-9]/g, ""); // Eliminar todos los caracteres que no sean números
        const numero = Number.parseInt(inputNumber);
        const newValue: NumericoMultipleValue = { ...internalValue, valor: isNaN(numero) ? 0 : numero };
        updateValue(newValue);
        const formattedNumber = inputNumber !== "" ? numero.toLocaleString('es-CO') : ""; // Formatear el número
        e.currentTarget.value = formattedNumber; // Actualizar el valor del campo de entrada
    };

    const getField = () => {
        const formattedValue = value && value.valor ? value.valor.toLocaleString('es-CO') : "";
        return <Form.Control type="text" disabled={multipleActivo} value={formattedValue} onChange= {onNumberChanged} style={{ width: "120px", marginLeft: "10px" }} />
    }

    const onAdd = (e: React.MouseEvent) => {
        addEmptyEntry()
    }

    const addEmptyEntry = () => {
            const newValue = internalValue?  { ...internalValue } : { valor: 0, items: []}
            const ids = newValue.items?.map(i => i.id);
            const newID = ids && ids.length>0 ? Math.max(...ids) + 1 : 0;
            let newEntryValue = 0;
            if (!newValue.items) {
                newValue.items = [];
                newEntryValue = newValue.valor ? newValue.valor : 0
            }
            newValue?.items?.push({ descripcion: "-", valor: newEntryValue, id: newID });
            updateValue(newValue);
    }

    const onRemove = (e: React.MouseEvent) => {
        const newItems = internalValue?.items?.filter(item => !selection.includes(item.id));
        const newValue = {...internalValue,  items: (newItems && newItems.length > 0) ? newItems : null }
        updateValue(newValue)
    }

    const onCellSave = (oldValue: any, newValue: string, row: ItemNumericoMultiple, column: ColumnDescription<ItemNumericoMultiple> & any) => {
        if(column.dataField ==="valor") {
            const parsedValue = Number.parseInt(newValue);
            if (parsedValue <= 0 || Number.isNaN(parsedValue) || !Number.isFinite(parsedValue)) {
                row.valor = 0;
            } else {
                row.valor = parsedValue;
            }
        } else if(column.dataField === "descripcion") {
            row.descripcion = newValue;
        }
        updateValue({...internalValue})
    }

    const handleOnSelect = (row: any, isSelect: boolean) => {
        if (isSelect) {
            setSelection([...selection, row.id]);
        } else {
            setSelection([...selection].filter(x => x !== row.id));
        }
    }

    const handleOnSelectAll = (isSelect: boolean, rows: Array<any>) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
            setSelection(ids)
        } else {
            setSelection([])
        }
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll
    } as SelectRowProps<any>;

    const rowStyle = (row: any, rowIndex: number) => {
        row.index = rowIndex;
        const style: any = {};
        style.backgroundColor = 'transparent';
        style.borderTop = 'none';
        return style;
    }

    const tableColumns = [{
        dataField: 'descripcion',
        text: 'Descripción'
    }, {
        dataField: 'valor',
        text: 'Valor',
        align: "right",
        formatter: (cell: any, row: ItemNumericoMultiple) => {
            const formatedNumber = numberFormat.format(cell);
            return (<>{formatedNumber}</>)
        }
    }];

    const getTable = () => {
        return (
            <>
                <Form.Group as={Row} >
                    <Col sm="12">
                        <div className="d-flex justify-content-end" >
                            {/* <InfoToolTip pregunta={pregunta}/> */}
                            &nbsp;
                            <Button style={{ width: "60px" }} variant="outline-primary" onClick={addEmptyEntry}><FontAwesomeIcon icon={faPlus} style={{ height: "auto" }} /></Button>
                            <Button style={{ width: "60px" }} variant="outline-secondary" onClick={onRemove}><FontAwesomeIcon icon={faMinus} style={{ height: "auto" }} /></Button>
                        </div>
                    </Col>
                </Form.Group>
                <Col sm="12">
                    {internalValue?.items &&
                        <BootstrapTable keyField='id' data={internalValue.items} columns={tableColumns}
                            selectRow={selectRow}
                            rowStyle={rowStyle}
                            cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell: onCellSave })} />}
                </Col>
            </>
        );
    }

    const activateMultiple = () => {
        setMultipleActivo(true);
        if (!internalValue?.items || internalValue?.items?.length === 0) {
            addEmptyEntry();
        }
    }

    const deactivateMultiple = () => {
        setMultipleActivo(false);
        setInternalValue({ ...internalValue, items: [] })
    }

    return (
        <>
            <Form.Group as={Row} controlId={pregunta.id} className={className}>
                <Form.Label column sm="6">
                    {pregunta.texto}
                </Form.Label>
                <Col sm="6">
                    <div className="d-flex justify-content-end" >              
                        &nbsp;
                        <ButtonGroup  >
                            <Button variant="primary" onClick={deactivateMultiple}>Total</Button>
                            <Button variant="secondary" onClick={activateMultiple}>Agregar más valores</Button>
                        </ButtonGroup>
                        {getField()}
                        <InfoToolTip id={pregunta.id} ayuda={pregunta.ayuda} />
                    </div>
                </Col>
            </Form.Group>
            {multipleActivo && getTable()}
        </>
    )
}

export default NumericoMultiple;