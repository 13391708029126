import React, { useState, PropsWithChildren} from "react";
import firebase from "firebase";
import { useCollectionOnce, useDocument } from "react-firebase-hooks/firestore";
import { Button, Carousel } from "react-bootstrap";
import { findIconDefinition, IconDefinition, IconLookup } from '@fortawesome/fontawesome-svg-core';
import StepNavigation, { IStep } from "./StepNavigation";
import { Loader } from "../../Loader";
import { Step } from "../../../dbTypes/Step";
import SiNo from "../SiNo";

export interface StepperProperties<T extends StepsDocument>{
    configPath: string
    done: (value:boolean) => void
    resultCalculation: (doc:T)=> boolean
}

export interface StepsDocument extends firebase.firestore.DocumentData {
    result:boolean;
}

function Stepper<T extends StepsDocument>({configPath,  done, resultCalculation}:PropsWithChildren<StepperProperties<T>>)  {
    const [index, setIndex] = useState(0);
    const [stepsConfigCollection, loadingConfig] = useCollectionOnce(firebase.firestore().collection(`${configPath}/steps`).orderBy('index', 'asc'));
    // const [dataDocumentSnap, loadingData] = useDocument(firebase.firestore().doc(dataPath));
    const [dataDocument, setDocumentData] = useState<{[kez:string]: any}>({});
    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };

    // const getDataDocuemnt = () => {
    //     if (!loadingData && dataDocumentSnap) {
    //         const doc = dataDocumentSnap.data()
    //         if (doc) {
    //             if(!doc.hasOwnProperty("result")){
    //                 doc["result"] = true;
    //             }
    //             return doc;
    //         }
    //     }
    //     return { result: true}
    // }
    // const dataDocument = getDataDocuemnt();

    const handleStepInput = async (key:string, value:any )=>{
        if(value !== undefined){
            const newDocData = {... dataDocument}
            newDocData[key] = value;
            newDocData.result = resultCalculation(newDocData as T);
            setDocumentData(newDocData);
            setIndex(index +1)
        }
    }

    let stepSymbols : IStep[]|undefined = undefined;
    let carouselItems : JSX.Element[]|undefined = undefined;

    if(!loadingConfig){
        let stepConfigs = stepsConfigCollection?.docs.map(d=>{
            const step = d.data() as Step
            step.id= d.id
            return step;
        });
        stepConfigs = stepConfigs?.filter((config, index, configs)=>{
            let showStep=false; 
            if(config.stepType === "question"){
                let shouldHide = false;
                if(config.hideConditions){
                    for(const key in config.hideConditions){
                        const value = config.hideConditions[key];
                        const dataValue = dataDocument[key];
                        shouldHide = shouldHide || (value === dataValue)
                    }
                }
                let shouldShow = false;
                if(config.showConditions){
                    for(const key in config.showConditions){
                        const value = config.showConditions[key];
                        const dataValue = dataDocument[key];
                        shouldShow = shouldShow || (value === dataValue)
                    }
                }
                showStep= (!!!config.hideConditions &&  !!!config.showConditions) || (!!config.showConditions && shouldShow) || (!!config.hideConditions && !shouldHide)
            } else if( config.stepType=== "finalInfo"){
                if(config.finalCondition === dataDocument.result){
                    showStep = true;
                }
            }
            return showStep;
        })

        stepSymbols = stepConfigs?.map(s => {
            const iconLookup: IconLookup = s.faIcon
            const iconconDefinition: IconDefinition = findIconDefinition(iconLookup)

            let stepDefinition : IStep = {
                title: s.title,
                icon: iconconDefinition
            }
            return stepDefinition;
        });

        carouselItems = stepConfigs?.map(s => {
            let item = (
                <Carousel.Item>
                    <div className="carousel-item-content " style={{zIndex:2, position:"relative"}} >
                        <h3>{s.title}</h3>
                        <div className="step-body" dangerouslySetInnerHTML={{__html: s.text}}></div>
                        {s.stepType === "question" && <SiNo value={dataDocument[s.id]} onValueChanged={(newValue)=> handleStepInput(s.id, newValue)} /> }
                        {/* {s.stepType === "finalInfo" && <Button variant="primary" onClick={_ =>done(true)} >Continuar</Button> } */}
                    </div>
                </Carousel.Item>
            )
            return item;
        });
    }

    return (
        <div>
            {loadingConfig && <Loader />}
            {!loadingConfig &&
            (<>
                <StepNavigation
                    activeColor="#120D4B"
                    completeColor="#1662A0"
                    steps={stepSymbols}
                    activeStep={index}
                />
                <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                    indicators={false}
                    wrap={false}
                    style={{height:"250px"}}
                >
                {carouselItems}
                </Carousel>
            </>)
            }
        </div>
    )
}

export default Stepper;