import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Overlay, { Placement } from 'react-bootstrap/esm/Overlay';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Hook that alerts clicks outside of the passed ref
 */
 const useOutsideAlerter = (ref:React.MutableRefObject<any>, callback: ()=>void) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event:any) {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            callback()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

const InfoToolTip: React.FC<{ id: string; ayuda: string; placement?: Placement; className?: string, containerClass?:string;}> = ({
  id,
  ayuda,
  placement = 'left',
  containerClass = '',
  className
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, ()=>{
    setShow(false);
  });

  const handleTooltipClick = () => {
    setShow(!show);
  };
 const showHelpIcon = ayuda && ayuda.length>2;

  return (
    <>
      <div ref={target} className={containerClass} style={{visibility: showHelpIcon? 'visible':'hidden' }}>

        <FontAwesomeIcon
          className={className}
          
          icon={faQuestionCircle}
          onClick={handleTooltipClick}
          style={{ height: '1.3em', width: 'auto', display: 'inline' }}
        />
      </div>
      <Overlay target={target.current} key={id} show={show} placement={placement} >
        <Tooltip id={`tooltip-${id}`}><div ref={wrapperRef} >{ayuda}</div></Tooltip>
      </Overlay>{' '}
    </>
  );
};

export default InfoToolTip;
