import React, { useState } from 'react';
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap';
import Paso1 from '../../assets/guia/1.png';
import Paso2 from '../../assets/guia/2.png';
import Paso3 from '../../assets/guia/3.png';
import Paso4 from '../../assets/guia/4.png';
import Paso5 from '../../assets/guia/5.png';
import Paso6 from '../../assets/guia/6.png';
import Paso7 from '../../assets/guia/7.png';
import Paso8 from '../../assets/guia/8.png';
import Paso9 from '../../assets/guia/9.png';
import Paso10 from '../../assets/guia/10.png';
import Paso11 from '../../assets/guia/11.png';
import Paso12 from '../../assets/guia/12.png';
import Paso13 from '../../assets/guia/13.png';
import Paso14 from '../../assets/guia/14.png';
import Paso15 from '../../assets/guia/15.png';
import Paso16 from '../../assets/guia/16.png';
import Paso17 from '../../assets/guia/17.png';
import Paso18 from '../../assets/guia/18.png';
import Paso19 from '../../assets/guia/19.png';
import Paso20 from '../../assets/guia/10.png';
import Paso21 from '../../assets/guia/21.png';
import Paso22 from '../../assets/guia/22.png';
import { useHistory } from 'react-router';

const SteapImgs = [
  Paso1,
  Paso2,
  Paso3,
  Paso4,
  Paso5,
  Paso6,
  Paso7,
  Paso8,
  Paso9,
  Paso10,
  Paso11,
  Paso12,
  Paso13,
  Paso14,
  Paso15,
  Paso16,
  Paso17,
  Paso18,
  Paso19,
  Paso20,
  Paso21,
  Paso22
];


export const GuiaDian = () => {
  const [index, setIndex] = useState(0);

  const history = useHistory()

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  const getInstructions = () => {
    switch (index) {
      case 0:
        return (
          <p>
            Lo primero que debes hacer es dirigirte a la página web de la DIAN  <a className="link"    href="http://www.dian.gov.co" target="_">www.dian.gov.co </a>, allí te diriges a su
            portal transaccional y das clic en Usuario Registrado
          </p>
        );
      case 1:
        return (
          <p>
            Lo primero que encontrarás será este aviso en el que únicamente tienes que aceptar términos y condiciones y
            dar clic en continuar
          </p>
        );
      case 2:
        return (
          <p>
            Ahora debes seleccionar tu tipo de documento e ingresarlo junto con la contraseña que hayas asignado. Sí no
            la recuerdas puedes hacer clic en el botón de ¿Olvidó su contraseña? Para asignar una nueva y si no cuentas
            con un usuario o tu cuenta se encuentra deshabilitada debes hacer clic en habilítela aquí
          </p>
        );
      case 3:
        return (
          <p>
            Para recuperar tu contraseña debes seleccionar tu tipo de documento e ingresar el número, dar clic al
            captcha y a continuar
          </p>
        );
      case 4:
        return (
          <p>
            Seleccionas la opción que te parezca mejor entre mensaje de texto o correo electrónico y das clic en enviar
            código
          </p>
        );

      case 5:
        return <p>Escribe la clave dinámica que te enviaron y da clic en validar</p>;

      case 6:
        return <p>Crea tu nueva contraseña con los requisitos que te salen en pantalla y guárdala</p>;

      case 7:
        return <p>¡Listo! Ya puedes iniciar sesión</p>;

      case 8:
        return (
          <p>
            Una vez ingreses encontrarás un dashboard que te permitirá hacer diferentes cosas como consultar tu exógena,
            gestionar o crear tú firma electrónica, obtener o actualizar tu RUT y claro, presentar tu declaración de
            renta en la sección de Diligenciar y presentar Formulario 210
          </p>
        );

      case 9:
        return (
          <p>
            Para descargar tu exógena o información reportada por terceros simplemente debes seleccionar la opción de
            Consultar información Exógena y aceptar las condiciones
          </p>
        );

      case 10:
        return (
          <p>
            Luego, simplemente seleccionas el año y das clic en consultar, automáticamente se descargará un archivo
            Excel en tu computador que debes buscar en la carpeta de Descargas y allí encontrarás toda la información
            que la DIAN tiene y que ha sido reportada por tus bancos o empleadores, entre otros
          </p>
        );

      case 11:
        return (
          <p>
            Pasando a la opción de Diligenciar y presentar Formulario 210 (tu declaración de renta), debes dirigirte a
            la sección de nuevo borrador, elegir el año que deseas diligenciar y hacer clic en el botón de crear
          </p>
        );
      case 12:
        return (
          <p>
            Lo primero que tendrás que responder es está ventana que se abre de manera automática, preguntándote si eres
            residente fiscal en Colombia, debes seleccionar Si y darle clic a enviar
          </p>
        );
      case 13:
        return (
          <p>
            A continuación, te informaran que tienes la opción de aplicar la declaración sugerida de renta al borrador
            que estas diligenciando, esto en resumen te permite adicionar automáticamente la información reportada por
            terceros o Exógena, a los respectivos renglones en que deban ir los valores, pero ten cuidado, normalmente
            las sumas que corresponden a deducciones o a rentas exentas no se diligencian de manera correcta por lo que
            no te debes confiar. Si bien es una buena opción para agilizar la presentación de tu declaración debes
            revisar bien los valores que se inserten en las casillas
          </p>
        );
      case 14:
        return (
          <p>
            Te permite observar previamente los valores que irían en las respectivas casillas, si quieres aplicarlos a
            tu borrador simplemente debes dar clic en SI, de lo contrario selecciona NO y comienza desde cero
          </p>
        );

      case 15:
        return (
          <p>
            Aquí comienza tú declaración, pero no deberás responder nada hasta la casilla 29 que corresponde a
            patrimonio bruto
          </p>
        );

      case 16:
        return (
          <p>
            Cada sección del formulario cuenta con ayudas de la DIAN en las que te indican de manera general que valores
            debes incluir
          </p>
        );

      case 17:
        return (
          <p>
            Cuando termines de diligenciar la información deberás hacer clic en el botón anaranjado y después en guardar
          </p>
        );
      case 18:
        return <p>Ahora nuevamente haces clic en el botón naranja pero seleccionas la opción de firmar</p>;
      case 19:
        return <p>Debes autorizar tu firma</p>;
      case 20:
        return <p>Ahora firmar</p>;
      case 21:
        return (
          <>
            <p>
              Te enviarán una clave dinámica a tu correo electrónico, la copias y pegas en el espacio indicado e
              ingresas tu clave de firma electrónica, das clic en firmar documento y ¡listo!
            </p>
            <p>Una vez este firmada la declaración, das clic nuevamente en el botón naranja y seleccionas presentar</p>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          {' '}
          {/* <Button onClick={()=> history.goBack()}>{'<-'}</Button> */}
          <h1 style={{ marginTop: '16px', marginBottom: '16px' }}> Guía de como declarla renta con la DIAN</h1>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <Carousel className='guia' activeIndex={index} onSelect={handleSelect} interval={null} wrap={false}>
            {SteapImgs.map((imgSrc) => (
              <Carousel.Item>
                <img className='guia-item' src={imgSrc} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col md="3" style={{ paddingLeft: '16px', paddingRight: '16px', width:'100%' }}>
          <div
           className='instruction'
          >
            {' '}
            {getInstructions()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
