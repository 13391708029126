import React from "react";

import { Navigation } from '../../components/Navigation';
import { Container } from "react-bootstrap";
import YoutubeEmbed from "./Youtube";

const DIANUsuario: React.FC = (props: any) => {
    return (
        <> 
            <Navigation />
            <Container>
            <h1>¿Como crear y habilitar tu cuenta de usuario en la DIAN?</h1>
            <h4>Este video te explica como puedes hacerlo…</h4>

<br/>
<p>🙌 Tú puedes realizar las diferentes gestiones y transacciones como persona natural o jurídica a través del 
    portal transaccional de la DIAN. Aquí te contamos cuáles son los pasos para habilitar tu cuenta de usuario
     registrado y acceder a estos servicios en línea o, en caso de olvido, recuperar tu contraseña de acceso. 😃</p>
        
        <p>Recuerda primero asegurarte de tener tu <a className="link"  href="/blog/RUT" target="_">RUT actualizado</a> </p>
        <YoutubeEmbed embedId="Hbvp-HTgBFE"></YoutubeEmbed>
                
            </Container>


           


        </>
    );
}

export { DIANUsuario  };