import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"

export interface SiNoNumeroValue {
    siNo: boolean | undefined
    numero: number | null
}

interface SiNoNumeroProperties {
    value: SiNoNumeroValue | undefined
    onValueChanged: (newValue: SiNoNumeroValue) => void
}
declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

const SiNoNumero: React.FC<SiNoNumeroProperties> = ({ value, onValueChanged }) => {
    const [internalValue, setInternalValue] = useState<SiNoNumeroValue | undefined>(value)

    const onsiNoChanged = (e: React.MouseEvent, value: boolean) => {
        const numero = value ? 0 : null
        const newValue: SiNoNumeroValue = { siNo: value, numero: numero }
        onValueChanged(newValue)
        setInternalValue(newValue)
    }

    const onNumberChanged = (e: React.ChangeEvent<FormControlElement>) => {
        const inputNumber = e.currentTarget.value.replace(/[^0-9]/g, ""); // Eliminar todos los caracteres que no sean números
        const numero = Number.parseInt(inputNumber);
        const formattedNumber = inputNumber !== "" ? new Intl.NumberFormat("es-CO").format(numero) : "" // Formatear el número
        const newValue: SiNoNumeroValue = { siNo: internalValue?.siNo, numero: isNaN(numero) ? null : numero }
        onValueChanged(newValue);
        setInternalValue(newValue);
        e.currentTarget.value = formattedNumber; // Actualizar el valor del campo de entrada
    };


    return (
        <div className="sino-numero-container d-flex">
            <div className="si-no-container">
                <Button
                    variant={internalValue?.siNo === true ? "primary" : "outline-primary"}
                    onClick={(e) => onsiNoChanged(e, true)}
                >
                    Si
                </Button>
                <Button
                    variant={internalValue?.siNo === false ? "secondary" : "outline-secondary"}
                    onClick={(e) => onsiNoChanged(e, false)}
                >
                    No
                </Button>
            </div>
            <Form.Control
                type="text" // Cambiar el tipo de entrada a texto
                disabled={!internalValue?.siNo}
                value={
                    internalValue?.numero !== null && internalValue?.numero !== undefined
                        ? new Intl.NumberFormat("es-CO").format(internalValue?.numero)
                        : ""
                }
                onChange={onNumberChanged}
                style={{ width: "120px", marginLeft: "10px" }}
            />
        </div>
    )
}

export default SiNoNumero
