import React from "react";
import { WorkInProgress } from "../components/WorkInProgress";
import { Navigation } from '../components/Navigation';

const ComoFunciona: React.FC = () => {
    return (
        <>
            <Navigation />
            <h1>Estamos trabajando para tí, pronto podrás ver todo el contenido...</h1>
            <WorkInProgress />
        </>
    )
}

export { ComoFunciona }