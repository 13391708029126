import React, { useEffect, useState, useRef } from "react"
import { NavLink, useHistory } from "react-router-dom";
import firebase from "firebase";
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Dropdown, Nav, Navbar, NavItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../styles/Navigation.css';
import logo from '../assets/Logo.svg';


const Navigation: React.FC = (props: any) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(!!firebase.auth().currentUser);
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string|null>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarRef = useRef<HTMLElement>(null);

  const toggle = () => setIsOpen(!isOpen);
  const user = { name: "Name", picture: "user.png" };
  const history = useHistory();

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      async (user) => {
        setAuthenticated(!!user)
        if (user) {
          if(user.displayName){
            setUsername(user.displayName);
            setEmail(user.email)
          }
        }
      }
    )
    return () => {
      unregisterAuthObserver();
    }
  },[user])

  const login = () => {
    history.push("ingresar")
  }

  const logout = () => {
    firebase.auth().signOut().then(() => {
      history.push("inicio");
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar 
        bg="#fff"
        expand="lg"
        fixed="top"
        className={`justify-content-end ${scrollPosition > 0 ? 'shadow' : ''}`}
        ref={navbarRef}
      >
        <Navbar.Brand href="/inicio">
          <img className="logo" src={logo} width='200px' alt="logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle onClick={toggle} />
        <Navbar.Collapse>
          <Nav id="navbar" navbar>
            {isAuthenticated && (
              <Nav.Link href="/declaraciones" id="menu">Mis Declaraciones</Nav.Link>
            )}
            {/* <Nav.Link href="/comofunciona" id="menu">¿Cómo funciona?</Nav.Link>
            <Nav.Link href="/preguntasfrecuentes" id="menu">Preguntas Frecuentes</Nav.Link> */}
            <Nav.Link href="/blog" id="menu">Ayudas</Nav.Link>
            <Nav.Link href="/contacto" id="menu">Contacto</Nav.Link>
            {!isAuthenticated && (
              <NavItem className="menu-bt">
                <Button
                  id="qsLoginBtn"
                  color="primary"
                  className="btn-margin"
                  onClick={login}
                  >
                  Ingresar
                </Button>
              </NavItem>
            )}
          </Nav>

          <Nav className="d-none d-md-block" navbar>
            {isAuthenticated && (
              <Dropdown className="nav-item">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  <FontAwesomeIcon icon="user-circle" className="mr-3" />
                </Dropdown.Toggle>
                <Dropdown.Menu down-centered>
                  <Dropdown.Header>
                    {username} <br/>
                    {email}
                  </Dropdown.Header>
                  <LinkContainer to="/declaraciones">
                    <Dropdown.Item
                    className="dropdown-profile"
                    activeClassName="router-link-exact-active"
                    >
                    <FontAwesomeIcon icon="user" className="mr-3" />
                      Perfil
                    </Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Item
                    id="qsLogoutBtn"
                    onClick={logout}
                  >
                    <FontAwesomeIcon icon="power-off" className="mr-3" />
                      Cerrar Sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
          {isAuthenticated && (
            <Nav
              className="d-md-none justify-content-between"
              navbar
              >
              <NavItem>
                <FontAwesomeIcon icon="user" className="mr-3" />
                <NavLink
                  to="/declaraciones"
                  activeClassName="router-link-exact-active"
                >
                  Perfil
                </NavLink>
              </NavItem>
              <NavItem>
                <FontAwesomeIcon icon="power-off" className="mr-3" />
                <NavLink
                  to="#"
                  id="qsLogoutBtn"
                  onClick={logout}
                  >
                  Cerrar Sesión
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export { Navigation };