import React, { useState } from "react";
import { Form } from "react-bootstrap";


declare type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface Props {
    value?:string;
    onValueChanged: (newValue:string) => void
    
}

const TextoLibre : React.FC<Props> = ({onValueChanged, value})=>{
    const doneTypingInterval = 3000;
    let typingTimer: NodeJS.Timeout; 
    const [internalValue, setInternalValue] = useState(value)
    const doneTyping = (newValue:string) => ()=> {
        onValueChanged(newValue);
    }
    const onTextChanged = (e: React.ChangeEvent<FormControlElement>) =>{
        clearTimeout(typingTimer)
        const newValue = e.currentTarget.value;
        setInternalValue(newValue)
        typingTimer = setTimeout( doneTyping(newValue),doneTypingInterval)
        
    }

    return <Form.Control type="text"  value={internalValue} onChange={onTextChanged} />
}

export default TextoLibre;