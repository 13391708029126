import { firestore } from "firebase";
import { Respuesta } from "simplypro-db";


export interface Respuestas {
    [preguntaId: string]: Respuesta;
  }
export interface DeclaracionDeRenta {
    definicionRef: firestore.DocumentReference;
    estado: string;
    id: string;
    perfil: string;
    titulo: string;
    respuestas: Respuestas;

}

    export const getValue =  <T>(declaracion:DeclaracionDeRenta, pregunta: string): T => {

        const respuesta = declaracion.respuestas[pregunta];
        let value :any = respuesta?.valor



        if(value?.numero){
            value = value.numero || 0;
        }

        if(value?.valor){
            value = value.valor || 0;
        }

        if(value instanceof Object && !value.seleccion){
            value = value.numero || 0;
        } 

        if(value !==0 &&  !value){
            if(respuesta?.tipo === "opción-múltiple"){
                value = {seleccion:[]}
            } else if(respuesta?.tipo === "si-no"){
                value = false;
            } else {
                value =0
            }
        }
        return value as T;
    }